/*
  Test page company control
  Allows companies to be added and removed from a test

  // requires: lib.js shim-template.js test-company.js
*/
/* global assure lang lib */

(function () {
  "use strict";

  // global settings
  assure.issue = { enabled: false, count: 0 };

  var demoform = lib.id("demoformtest"),
    issue = {
      readonly: !!lib.id("readonly"), // readonly page
      container: lib.id("testissues"), // issues container
      infotable: lib.id("issueinfo"), // information table
      link: lib.id("issuelink"), // list of linked issues
      linktemplate: lib.id("linktemplate"), // template of linked issue list item
      commenttemplate: lib.id("commenttemplate"), // template of comment item
      assigneetemplate: lib.id("assigneetemplate"), // template of assignee fieldset
      assign: lib.id("assign"), // issue assign block
      assignees: lib.id("assignees"), // issue assignees block
      discuss: lib.id("discuss"), // issue discussion block
      comments: lib.id("comments"), // issue comments
    },
    atv = "active";

  var company = {
      template: lib.id("companytemplate"),
    },
    canaddnew = lib.id("canaddnew");

  if (company.template) company.container = company.template.parentNode;

  // no support or issue fields
  if (!issue.container || !issue.infotable || !window.addEventListener) return;

  // secondary assignee template
  if (issue.assigneetemplate) {
    issue.secassignee = {
      name: issue.assigneetemplate.content.querySelector("select"),
      namelabel:
        issue.assigneetemplate.content.querySelector("select")
          .nextElementSibling,
      notice: issue.assigneetemplate.content.querySelector("input"),
      noticelabel:
        issue.assigneetemplate.content.querySelector("input")
          .nextElementSibling,
    };
  }

  // secondary assignee index
  var sAIndex = Number(lang.secassigneeslength || "0");

  // information
  issue.info = {
    testcount: lib.query("a", lib.id("infotestcount")), // test count text
    dateopen: lib.id("infodateopen"), // date opened
    dateack: lib.id("infodateack"), // date acknowledged
    datefix: lib.id("infodatefixed"), // date fixed
    dateclose: lib.id("infodateclosed"), // date closed
  };

  // control buttons
  issue.button = {
    add: lib.query('a[href="#issuelinker"]', issue.container), // link button
    del: lib.query('a[href="#issueremove"]', issue.container), // remove button
    res: lib.query('a[href="#issuerespond"]', issue.container), // respond button
    ack: lib.query('a[href="#issueacknowledged"]', issue.container), // acknowledge button
    fix: lib.query('a[href="#issuefixed"]', issue.container), // fixed button
    acc: lib.query('a[href="#issueacceptfix"]', issue.container), // accept fix button
    end: lib.query('a[href="#issueclosed"]', issue.container), // close button
    com: lib.id("commentbuttons"), // comment buttons
  };

  issue.addassignee = lib.query('a[href="#addassignee"]', issue.container); // add assignee button
  // issue.addregulator = lib.query('a[href="#addregulator"]', issue.container);		// add regulator button

  // get button parent nodes
  for (var b in issue.button) {
    if (issue.button[b]) issue.button[b] = issue.button[b].parentNode;
  }

  // // show/hide breaches button
  issue.button.shb = lib.className("showhide", issue.container);
  issue.button.shb = issue.button.shb.length ? issue.button.shb[0] : null;

  // issue fields
  issue.field = {
    breachnotes: lib.id("breachnotes"),
    assignee: lib.id("assignee"),
    secassignee: lib.queryAll('[id^="secassignee-"]'),
    // secassigneenotice: lib.queryAll('[id^="secassigneenotice-"]'),
    regulator: lib.id("regulator"),
    regulatornotice: lib.id("regulatornotice"),
    comment: lib.id("comment"),
    testid: lib.query('input[name="_id"]'), // id of test (hidden)
    id: lib.id("issue"), // id of linked issue (hidden)
    testcount: lib.id("testcount"), // issue testcount (hidden)
    link: issue.linktemplate
      ? issue.linktemplate.content.querySelector("a")
      : null,
  };

  issue.counter = lib.query('a[href="#testissues"] sup'); // menu issue counter
  issue.country = lib.id("country"); // country selector

  // breach checkboxes
  issue.breach = {
    subscriptionauto:
      lib.id("breach-" + issue.country.value + "-subscriptionauto") ||
      lib.id("breach-xx-subscriptionauto"),
  };

  // status validation
  issue.status = lib.id("status");
  if (issue.status) {
    assure.validate = assure.validate || [];
    assure.validate.push(validateStatus);
  }

  // duplicate issue checkbox
  issue.duplicate = lib.id("dupissue");

  // comment template
  if (issue.commenttemplate) {
    issue.comment = {
      li: issue.commenttemplate.content.querySelector("li"),
      bq: issue.commenttemplate.content.querySelector("blockquote"),
      type: issue.commenttemplate.content.querySelector("strong"),
      text: issue.commenttemplate.content.querySelector("p").lastChild,
      cite: issue.commenttemplate.content.querySelector("cite"),
      img: issue.commenttemplate.content.querySelector("img"),
    };
  }

  // start
  init();

  // initialise
  function init() {
    // update issue details
    issueCheck();

    // issue change events
    issue.container.addEventListener("change", issueCheck, false);

    // auto-subscription selected
    if (issue.breach.subscriptionauto)
      issue.breach.subscriptionauto.addEventListener(
        "change",
        autoSubSelect,
        false,
      );

    // duplicate-issue checkbox event
    if (issue.duplicate)
      issue.duplicate.addEventListener("change", updateTest, false);

    // button events
    if (issue.button.add)
      issue.button.add.addEventListener("click", linkIssue, false);
    if (issue.button.del)
      issue.button.del.addEventListener("click", removeIssue, false);

    // comment button events
    if (issue.button.com)
      issue.button.com.addEventListener("click", respondIssue, false);

    // link to issue event
    if (issue.link)
      issue.link.addEventListener("click", linkIssueAttach, false);

    // country change event
    if (issue.country)
      issue.country.addEventListener("change", changeCountry, false);

    // show/hide breaches
    if (issue.button.shb) {
      showHideBreach();
      issue.button.shb.addEventListener("click", showHideBreach, false);
    }

    // add assignee field button event
    if (issue.addassignee)
      issue.addassignee.addEventListener("click", newSecAssignee, false);

    // add regulator field button event
    // if (issue.addregulator) issue.addregulator.addEventListener('click', newSecRegulator, false);

    // link in comment clicked
    lib.each(lib.queryAll("a.commentlink"), function (a) {
      a.addEventListener("click", openLink, false);
    });

    // company change events
    document.addEventListener("company", changeCompany, false);

    // fetch company data
    var c,
      done = 0;
    for (c in assure.company) {
      if (!assure.company[c].new) {
        done++;
        fetchCompany(c, companyComplete);
      }
    }

    // company fetch is complete
    function companyComplete() {
      done--;
      if (done === 0) updateSelectors();
    }

    // show after initialisation
    issue.container.classList.add(atv);
  }

  // change country - remove issue
  function changeCountry() {
    issueReset();
    updateSelectors();
    issueCheck(null, true);
  }

  // change company
  function changeCompany(e) {
    var comp = e.detail.name;

    if (e.detail.type == "add" && !assure.company[comp].new) {
      // existing company added
      fetchCompany(comp, function (name) {
        if (
          assure.company[name].info.regulators ||
          assure.company[name].info.assignees
        )
          updateSelectors();
      });
    } else if (
      e.detail.type == "add" &&
      assure.company[comp].new &&
      canaddnew?.value !== "true"
    ) {
      // only authorised users can add a new company
      var modal = {
        header: lang.companynew,
        message: lang.companynewnotallowed,
        buttons: {},
      };
      modal.buttons[lang.OK] = 1;
      lib.modal(modal);

      var companies = document.querySelectorAll(`input[name="company"]`);

      companies.forEach(function (c) {
        if (c.value === comp) {
          // remove company
          company.container.removeChild(c.parentNode);
        }
      });
    } else if (e.detail.type == "remove") {
      // company removed
      updateSelectors();
    }

    // remove existing list
    linkReset();
    issueCheck(null, true);
  }

  // start link issue
  function linkIssue(e) {
    e.preventDefault();
    if (
      issueCheck() ||
      issue.button.add.firstElementChild.classList.contains(atv)
    )
      return;

    // fetch issue list
    issue.button.add.firstElementChild.classList.add(atv);

    // generate web service parameters
    var c,
      comp = [],
      WS =
        "/issue/list/?country=" +
        issue.country.options[issue.country.selectedIndex].value +
        "&company=";
    for (c in assure.company) comp.push(c);
    WS += encodeURIComponent(comp.join(";"));

    lib.ajax(WS, function (err, url, idata) {
      issue.button.add.firstElementChild.classList.remove(atv);
      if (err || !idata || !idata.length) {
        // no matching issues
        var modal = {
          header: lang.issues,
          message: lang.issuenone,
          buttons: {},
        };
        modal.buttons[lang.OK] = 1;
        lib.modal(modal);
        return;
      }

      // add issue links
      var i, d, newLink, secassignees;
      for (i = 0; i < idata.length; i++) {
        d = idata[i];

        if (d.secassignee && Array.isArray(d.secassignee)) {
          secassignees = "";
          d.secassignee.forEach((a) => (secassignees += a.name + ","));
        } else if (d.secassignee && typeof d.secassignee === "string")
          secassignees = d.secassignee + ",";

        issue.field.link.dataset.id = d.id;
        issue.field.link.parentNode.className = "status" + d.status;
        issue.field.link.textContent =
          d.testcount +
          " " +
          lang.tests +
          " - " +
          d.breachcount +
          " " +
          lang.issues +
          (d.open ? " - " + d.open : "") +
          (d.assignee ? " - " + lang.assignee + " " + d.assignee : "") +
          (secassignees
            ? " - " +
              lang.secassignee +
              " " +
              secassignees.slice(0, secassignees.length - 1)
            : "") +
          (d.regulator ? " - " + lang.regulator + " " + d.regulator : "");

        issue.field.link.title =
          (d.acknowledged ? lang.acknowledged + " " + d.acknowledged : "") +
          (d.fixed ? ", " + lang.fixed + " " + d.fixed : "");

        newLink = document.importNode(issue.linktemplate.content, true);
        issue.link.appendChild(newLink);
      }

      // update display
      issue.button.add.style.display = "none";
      if (issue.link) issue.link.style.display = "block";
    });
  }

  // attach to an issue
  var attaching = false;
  function linkIssueAttach(e) {
    e.preventDefault();
    var t = e.target;
    if (attaching || !t.dataset || !t.dataset.id || t.dataset.id.length != 24)
      return;

    attaching = true;

    lib.ajax("/issue/" + t.dataset.id, function (err, url, idata) {
      attaching = false;
      if (err || !idata) return;

      if (issueCheck()) {
        // verify issue can be overwritten
        var modal = {
          header: lang.remove + " " + lang.issues,
          message: lang.sure,
          buttons: {},
          callback: function (confirm) {
            if (confirm) populateIssue(idata);
          },
        };
        modal.buttons[lang.remove] = 1;
        modal.buttons[lang.cancel] = "";
        lib.modal(modal);
      } else populateIssue(idata);
    });
  }

  // populate issue
  function populateIssue(idata) {
    issueReset();

    // hidden values
    issue.field.id.value = idata._id;
    issue.field.testcount.value = idata.testcount + 1;

    // information table
    issue.info.testcount.href = "/test/list/?issueid=" + idata._id;
    issue.info.testcount.textContent = idata.testcount + 1;
    issue.info.dateopen.textContent = idata.open || "";
    issue.info.dateack.textContent = idata.acknowledged || "";
    issue.info.datefix.textContent = idata.fixed || "";
    issue.info.dateclose.textContent = idata.closed || "";

    // check breaches
    var c,
      bc,
      bsect = lib.id("breach-" + issue.country.value) || lib.id("breach-xx");
    for (c = 0; c < idata.breach.length; c++) {
      bc = lib.id(bsect.id + "-" + idata.breach[c], bsect);
      if (bc) bc.checked = true;
    }

    // breach notes
    issue.field.breachnotes.value = idata.breachnotes;

    // status
    lib.setSelect(issue.status, idata.status);
    c = lib.appliedStyle(
      issue.status.options[issue.status.selectedIndex],
      "border-top-color",
    );
    issue.status.style.backgroundColor = c || "transparent";

    // assignee
    lib.setSelect(issue.field.assignee, idata.assignee);

    // regulator
    lib.setSelect(issue.field.regulator, idata.regulator);
    issue.field.regulatornotice.value = idata.regulatornotice;

    // secondary assignee
    if (!Array.isArray(idata.secassignee)) {
      newSecAssignee();
      let secname = lib.id(`secassignee-${sAIndex - 1}`);
      let secnotice = lib.id(`secassigneenotice-${sAIndex - 1}`);
      lib.setSelect(secname, idata.secassignee);
      secnotice.value = idata.secassigneenotice;
    } else if (Array.isArray(idata.secassignee)) {
      for (let a of idata.secassignee) {
        newSecAssignee();
        let secname = lib.id(`secassignee-${sAIndex - 1}`);
        let secnotice = lib.id(`secassigneenotice-${sAIndex - 1}`);
        lib.setSelect(secname, a.name);
        secnotice.value = a.notice;
      }
    }

    // comments
    for (c = 0; c < idata.comment.length; c++) {
      newComment(idata.comment[c]);
    }

    // update
    issueCheck(null, true);
  }

  // add a new comment
  function newComment(comment, animate) {
    issue.comment.li.className = animate ? "animate" : "";
    issue.comment.bq.classList.remove(comment.isregulator ? "client" : "admin");
    issue.comment.bq.classList.add(comment.isregulator ? "admin" : "client");
    issue.comment.type.textContent = comment.type || "";
    issue.comment.text.nodeValue = comment.text || "";
    issue.comment.cite.lastChild.nodeValue =
      comment.name +
      (comment.company ? ", " + comment.company : "") +
      ", " +
      comment.date;
    issue.comment.cite.dataset.userid = comment.user;
    issue.comment.img.src = comment.avatar;
    issue.comment.img.alt = comment.name;

    var newComment = document.importNode(issue.commenttemplate.content, true);
    issue.comments.appendChild(newComment);

    // scroll down
    issue.comments.scrollTop = issue.comments.scrollHeight;

    // animation
    if (animate) {
      setTimeout(function () {
        var li = lib.query("li.animate:not(.in)", issue.comments);
        if (li) li.classList.add("in");
      }, 100);
    }
  }

  // go to link
  function openLink(e) {
    var link = e.target.href;
    window.open(link);
  }

  // respond button
  function respondIssue(e) {
    e.preventDefault();
    e.stopPropagation();

    var t = e.target,
      lnk = t ? lib.closest("a", t) : null,
      testId = issue.field.testid.value,
      issueId = issue.field.id.value,
      text = issue.field.comment.value.trim(),
      type = lnk ? lnk.hash.slice(6) : null;

    // invalid
    if (
      !lnk ||
      !testId ||
      !issueId ||
      !type ||
      (type == "respond" && !text) ||
      issue.button.com.classList.contains(atv)
    )
      return;

    // processing indicator
    issue.button.com.classList.add(atv);
    lnk.classList.add(atv);

    if (!demoform) {
      // send request
      lib.ajax(
        { action: "/issue/sendmessage/", method: "POST" },
        function (err, url, data) {
          // stop processing indicator
          issue.button.com.classList.remove(atv);
          lnk.classList.remove(atv);

          if (!err && data) {
            // update information
            issue.info.dateopen.textContent = data.update.open;
            issue.info.dateack.textContent = data.update.acknowledged;
            issue.info.datefix.textContent = data.update.fixed;
            issue.info.dateclose.textContent = data.update.closed;

            // remove comment
            issue.field.comment.value = "";

            // update comment
            newComment(data, true);

            // update issue
            issueCheck(null, true);
          }
        },
        function (fd) {
          // append form values
          fd.append("test", testId);
          fd.append("issue", issueId);
          fd.append("type", type);
          fd.append("text", text);
          return fd;
        },
      );
    }

    // demo test
    else {
      // update information
      if (type === "acknowledged")
        issue.info.dateack.textContent = new Date()
          .toISOString()
          .slice(0, 16)
          .replace("T", " ");
      if (type === "fixed")
        issue.info.datefix.textContent = new Date()
          .toISOString()
          .slice(0, 16)
          .replace("T", " ");

      // remove comment
      issue.field.comment.value = "";

      // update comment
      newComment(
        {
          avatar: "/avatar/demouser",
          company: "Demo Merchant",
          date: new Date().toISOString().slice(0, 16).replace("T", " "),
          isregulator: false,
          name: "Demo User",
          text: text,
          type: type,
        },
        false,
      );
    }
  }

  // remove issue
  function removeIssue(e) {
    if (e && e.preventDefault) e.preventDefault();
    if (!issueCheck()) return;

    var modal = {
      header: lang.remove + " " + lang.issues,
      message: lang.sure,
      buttons: {},
      callback: function (confirm) {
        if (confirm) {
          // reset or clone
          if (confirm == 1) issueReset();
          else issueUnlink();
          issueCheck();
        }
      },
    };
    modal.buttons[lang.remove] = 1;
    if (lib.int(issue.field.testcount.value) > 1) modal.buttons[lang.clone] = 2;
    modal.buttons[lang.cancel] = "";
    lib.modal(modal);
  }

  // // alert auto-subscription message
  function autoSubSelect(e) {
    if (e.target.checked) {
      var modal = {
        header: lang.issues,
        message: lang.autosubissue,
        buttons: {},
      };
      modal.buttons[lang.OK] = null;
      lib.modal(modal);
    }
  }

  // returns count of set fields
  var lastShow,
    issuesSet = issue.counter ? lib.int(issue.counter.textContent) : 0;
  function issueCheck(e, force) {
    var set, setOn, multiFixersAllowed;

    if (issue.readonly) {
      // no live processing
      set = lib.int(issue.counter.textContent);
      setOn = !!set;
    } else {
      // secondary assignee can be set
      multiFixersAllowed = lang.secassigneecountry
        .split(",")
        .includes(issue.country.value);

      // check checkboxes
      var bsect =
        lib.id("breach-" + issue.country.value) || lib.id("breach-xx");
      set =
        (lib.queryAll("input:checked", bsect) || []).length +
        ((
          (issue.field.breachnotes && issue.field.breachnotes.value) ||
          ""
        ).trim()
          ? 1
          : 0);

      setOn = !!set;
    }

    assure.issue.enabled = setOn;
    assure.issue.count = set;

    // change buttons
    if (force || setOn !== lastShow) {
      var tc = lib.int(issue.field.testcount.value);

      // element display
      if (issue.button.add)
        issue.button.add.style.display =
          setOn || issue.link.children.length ? "none" : "inline-block";
      // if (issue.assign) issue.assign.style.display = (setOn ? 'flex' : 'none');
      if (issue.field.assignee)
        issue.field.assignee.parentNode.style.display = setOn ? "flex" : "none";
      if (issue.addassignee)
        issue.addassignee.style.display =
          setOn && multiFixersAllowed ? "block" : "none";
      if (issue.addregulator)
        issue.addregulator.style.display =
          setOn && multiFixersAllowed ? "block" : "none";
      if (lib.queryAll('[id^="secassignee-"]').length) {
        // hide all sec-assignee fieldsets
        Array.from(lib.queryAll('[id^="secassignee-"]')).forEach(function (s) {
          lib.query(`[data-selectid=${s.id}]`).style.display =
            setOn && multiFixersAllowed ? "flex" : "none";
        });
      }
      if (issue.field.regulator)
        issue.field.regulator.parentNode.style.display = setOn
          ? "flex"
          : "none";
      if (issue.field.regulatornotice)
        issue.field.regulatornotice.parentNode.style.display = setOn
          ? "flex"
          : "none";
      if (issue.discuss) issue.discuss.style.display = setOn ? "block" : "none";
      if (issue.button.del)
        issue.button.del.style.display = setOn ? "inline-block" : "none";
      if (issue.infotable)
        issue.infotable.style.display = setOn && tc ? "block" : "none";

      if (setOn) {
        // update information table
        issue.info.testcount.textContent = tc;

        // comments
        if (issue.comments) {
          var sc = !!issue.comments.children.length;
          issue.comments.parentNode.style.display = sc ? "flex" : "none";
          if (sc) issue.comments.scrollTop = issue.comments.scrollHeight;
        }

        // comment box size reset
        if (issue.field.comment && assure && assure.textarea)
          assure.textarea.contentChange(issue.field.comment);

        // set most likely assignee and regulator
        if (setOn !== lastShow && issue.field.assignee)
          issue.field.assignee.selectedIndex =
            issue.field.assignee.options.length > 1 ? 1 : 0;
        // if (setOn !== lastShow && issue.field.secassignee) issue.field.secassignee.selectedIndex = (issue.field.secassignee.options.length > 1 ? 1 : 0);
        if (setOn !== lastShow && issue.field.regulator)
          issue.field.regulator.selectedIndex =
            issue.field.regulator.options.length > 1 ? 1 : 0;

        // action buttons
        var info = {},
          i,
          newIssue = !(issue.field.id && issue.field.id.value),
          newTest = !issue.field.testid.value;
        for (i in issue.info) {
          info[i] = !!issue.info[i].textContent;
        }

        var admin = issue.button.res ? !!issue.button.res.dataset.admin : false;
        if (issue.field.comment)
          issue.field.comment.parentNode.style.display =
            info.dateclose && !admin ? "none" : "flex";

        if (issue.button.res)
          issue.button.res.style.display =
            newTest ||
            newIssue ||
            (issue.button.ack && !info.dateack) ||
            (info.dateclose && !admin)
              ? "none"
              : "inline-block";
        if (issue.button.ack)
          issue.button.ack.style.display = info.dateack
            ? "none"
            : "inline-block";
        if (issue.button.fix)
          issue.button.fix.style.display = info.datefix
            ? "none"
            : "inline-block";
        if (issue.button.acc)
          issue.button.acc.style.display =
            newTest || newIssue || info.dateclose ? "none" : "inline-block";
        if (issue.button.end)
          issue.button.end.style.display =
            newTest || newIssue || info.dateclose ? "none" : "inline-block";
      }

      // complete
      lastShow = setOn;
    }

    // interface updates
    if (set !== issuesSet) {
      issuesSet = set;

      // issue counter update
      if (issue.counter) {
        issue.counter.textContent = set;
      }
    }

    return set;
  }

  // reset issue fields
  function issueReset() {
    // unset checkboxes
    var cb = lib.queryAll("input:checked", issue.container),
      c;
    for (c = 0; c < cb.length; c++) {
      if (!cb[c].hasAttribute("data-dupissue")) {
        cb[c].checked = false;
      }
    }

    // remove breachnotes
    issue.field.breachnotes.value = "";

    // remove comments
    lib.empty(issue.comments);
    if (issue.field.comment) issue.field.comment.value = "";

    // change regulator notice period
    if (issue.field.regulatornotice) issue.field.regulatornotice.value = 24;

    // remove link
    issueUnlink();
  }

  // reset links
  function linkReset() {
    if (issue.link) issue.link.style.display = "none";
    lib.empty(issue.link);
  }

  // unlink issue
  function issueUnlink() {
    // reset information
    for (var i in issue.info) issue.info[i].textContent = "";

    // reset comments
    lib.empty(issue.comments);
    if (issue.field.comment) issue.field.comment.value = "";

    // reset hidden values
    issue.field.id.value = "";
    issue.field.testcount.value = "0";

    // show all checkboxes
    showHideBreach(null, 1);
  }

  // validate status - is issues are set, status must not be low or less
  function validateStatus() {
    if (
      assure.issue.enabled &&
      lib.int(issue.status[issue.status.selectedIndex].value) <= 1000
    ) {
      var modal = {
        header: lang.issues,
        message: lang.statusissuefail,
        buttons: {},
      };
      modal.buttons[lang.OK] = null;
      lib.modal(modal);

      return false;
    }

    return true;
  }

  // add a new secondary assignee
  function newSecAssignee() {
    issue.secassignee.name.id = `secassignee-${sAIndex}`;
    issue.secassignee.namelabel.htmlFor = `secassignee-${sAIndex}`;
    issue.secassignee.notice.id = `secassigneenotice-${sAIndex}`;
    issue.secassignee.noticelabel.htmlFor = `secassigneenotice-${sAIndex}`;

    var newSA = document.importNode(issue.assigneetemplate.content, true);
    issue.assignees.appendChild(newSA);

    lib
      .id(`secassignee-${sAIndex}`)
      .closest(
        "fieldset#assignees > div",
      ).dataset.selectid = `secassignee-${sAIndex}`;

    // flat array for sorting
    let cList = [];
    let opt;

    for (let c in assure.company) {
      if (assure.company[c].info) cList.push(assure.company[c].info);
    }

    cList.sort(function (a, b) {
      return b.assignees - a.assignees;
    });

    // append new company nodes
    for (let cl = 0; cl < cList.length && cList[cl].assignees > 0; cl++) {
      opt = document.createElement("option");
      opt.value = cList[cl].name;
      opt.textContent = cList[cl].name;
      lib.id(`secassignee-${sAIndex}`).add(opt);
    }

    // increase sec-assignee index
    sAIndex++;
  }

  // update company selector
  function updateSelectors() {
    // flat array for sorting
    var c,
      cList = [];
    for (c in assure.company) {
      if (assure.company[c].info) cList.push(assure.company[c].info);
    }

    // update box
    var selector = function (name) {
      if (!issue.field[name]) return;

      var iName = name.includes("sec") ? name.slice(3) + "s" : name + "s",
        idx = 0,
        sel = "",
        c,
        opt,
        comp,
        newS = 0;

      cList.sort(function (a, b) {
        return b[iName] - a[iName];
      });

      // primary assignee or regulator
      if (!name.includes("sec")) {
        idx = issue.field[name].selectedIndex;
        sel = issue.field[name].options[idx > 0 ? idx : 0].value;

        // remove all values except empty
        do {
          c = issue.field[name].options.length - 1;
          if (c > 0)
            issue.field[name].removeChild(issue.field[name].options[c]);
        } while (c);

        // append new company nodes
        for (c = 0; c < cList.length && cList[c][iName] > 0; c++) {
          comp = cList[c].name;
          opt = document.createElement("option");
          opt.value = comp;
          opt.textContent = comp;
          issue.field[name].appendChild(opt);
          if (comp == sel) newS = c + 1;
        }

        // choose first option
        issue.field[name].selectedIndex = newS;
      }
      // secondary assignees or regulators
      else {
        var secondaryFixers = lib.queryAll(`[id^="${name}-"]`);

        Array.from(secondaryFixers).forEach(function (f) {
          idx = f.selectedIndex;
          sel = f.options[idx > 0 ? idx : 0].value;

          // remove all values except empty
          do {
            c = f.options.length - 1;
            if (c > 0) f.removeChild(f.options[c]);
          } while (c);

          // append new company nodes
          for (c = 0; c < cList.length && cList[c][iName] > 0; c++) {
            comp = cList[c].name;
            opt = document.createElement("option");
            opt.value = comp;
            opt.textContent = comp;
            f.appendChild(opt);
            if (comp == sel) newS = c + 1;
          }

          // choose first option
          f.selectedIndex = newS;
        });
      }
    };

    // set assignee and regulator selector
    selector("assignee");
    selector("regulator");
    // allowed in specific countries
    if (lang.secassigneecountry.split(",").includes(issue.country.value))
      selector("secassignee");
    // if (lang.secassigneecountry.split(',').includes(issue.country.value)) selector('secregulator');
  }

  // fetch company details
  function fetchCompany(name, callback) {
    // ajax call
    lib.ajax(
      "/company/" + encodeURIComponent(name),
      function (err, url, cdata) {
        if (!err && cdata) {
          assure.company[name].info = cdata;
        }

        callback(name);
      },
    );
  }

  // show/hide active breaches
  function showHideBreach(e, force) {
    var state = parseInt(issue.button.shb.dataset.state, 10),
      bsect = lib.id("breach-" + issue.country.value) || lib.id("breach-xx"),
      cbox = lib.tag("input", bsect),
      cb;

    if (e) {
      // toggle state
      e.preventDefault();
      state = ++state % 2;
    }

    // force state
    if (typeof force !== "undefined") state = force;

    // update state
    issue.button.shb.dataset.state = state;

    // update button text
    issue.button.shb.textContent =
      issue.button.shb.dataset[state ? "hide" : "show"];

    // update checkboxes
    for (cb = 0; cb < cbox.length; cb++) {
      if (state == 1 || cbox[cb].checked) {
        cbox[cb].parentNode.style.display = "flex";
      } else {
        cbox[cb].parentNode.style.display = "none";
      }
    }
  }

  // update test status and remove issue
  function updateTest(e) {
    let t = e.target;
    if (t.checked) {
      lib.setSelect(issue.status, 1000);
      let c = lib.appliedStyle(
        issue.status.options[issue.status.selectedIndex],
        "border-top-color",
      );
      issue.status.style.backgroundColor = c || "transparent";

      removeIssue();
    }
  }
})();
