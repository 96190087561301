/*
  Country Network control
  Determines how the network field is modified when changing country

  // requires: lib.js form.js test-network.js
*/
/* global lib */

(function () {
  "use strict";

  // settings
  var cnet = {
    gm: lib.id("gm"), // GM selector
    country: lib.id("country"), // country field
    gmcountry: lib.id("gmcountry"), // gmcountry field
    network: lib.id("network"), // network field
  };

  // no support or functionality not required
  if (
    !cnet.country ||
    !cnet.network ||
    !lib.query("option[data-country]", cnet.network) ||
    !window.addEventListener
  )
    return;

  var curCty = "", // current country
    group = !!cnet.network.dataset.group, // grouping
    reset = cnet.network.cloneNode(true);

  reset.selectedIndex = -1;

  init();

  // country or gmcountry change
  cnet.country.addEventListener("change", countryChange, false);
  if (cnet.gmcountry)
    cnet.gmcountry.addEventListener("change", countryChange, false);

  function countryChange(e) {
    countryNetworks(e.target.value);
  }

  // GM selector change
  if (cnet.gm) {
    cnet.gm.addEventListener(
      "change",
      function () {
        setTimeout(init, 1);
      },
      false,
    );
  }

  function init() {
    countryNetworks(activeCountry().value, true);
  }

  // find active country control
  function activeCountry() {
    return cnet.gmcountry && cnet.gmcountry.offsetHeight
      ? cnet.gmcountry
      : cnet.country;
  }

  // highlight country networks
  function countryNetworks(country, init) {
    if (country === curCty) return;

    var netval = cnet.network.value || "",
      net = reset.cloneNode(true);

    // order options according to country
    if (country) {
      var ins,
        fo = 0,
        o = net.options.length - 1,
        opt;

      if (group) {
        // create country group
        var ac = activeCountry(),
          cg = document.createElement("optgroup");

        cg.setAttribute("label", ac.options[ac.selectedIndex].text);
        ins = net.insertBefore(cg, net.firstElementChild);
        fo++;
      } else {
        ins = net;
      }

      // analyse options in reverse
      while (o >= fo) {
        opt = net.options[o];

        if (
          opt.value === "" ||
          opt.value === "xx" ||
          (opt.dataset.country || "").indexOf(country) >= 0 ||
          (init && opt.value === netval)
        ) {
          // move option to top
          ins.insertBefore(opt, ins.firstElementChild);
          fo++;
        } else {
          // remove option
          if (!group) net.removeChild(opt);
          o--;
        }
      }
    }

    // remove empty group
    if (group && !ins.childElementCount) net.removeChild(ins);

    // update DOM
    lib.empty(cnet.network);
    while (net.children.length) {
      cnet.network.insertBefore(
        net.lastElementChild,
        cnet.network.firstElementChild,
      );
    }

    // set option
    lib.setSelect(cnet.network, netval);

    curCty = country;
  }
})();
