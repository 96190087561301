/*
  disallow manual test publishing if certain fields are emptry or invalid

  // requires: lib.js test-company.js
*/
/* global assure lang lib */

(function () {
  "use strict";

  var cfg = {
      class: "invalid",
    },
    fields = {
      brand: lib.id("brand"),
      classification: lib.id("classification"),
      urllist: lib.id("urllist"),
      publish: lib.query("button[value=publish]"),
      companyadd: lib.id("companyadd"),
      assignee: lib.id("assignee"),
      secassignee: lib.queryAll('[id^="secassignee-"]'),
      regulator: lib.id("regulator"),
      status: lib.id("status"),
    },
    media = lib.id("media"),
    newmedia = lib.id("newmedia"),
    testtype = lib.id("testtype"),
    companies = assure.company,
    showClaModal = true,
    showMedModal = true,
    showAssModal = true,
    showSecAssModal1 = true,
    showSecAssModal2 = true,
    showSecAssModal3 = true,
    showRegModal = true;

  // no suppport
  if (
    !fields.brand ||
    !fields.classification ||
    !fields.urllist ||
    !fields.publish ||
    !window.addEventListener ||
    !document.body.classList
  )
    return;

  fields.publish.addEventListener("click", validateFields, false);

  companyadd.addEventListener(
    "change",
    function (e) {
      let m = e.target.value;
      if (!m.endsWith(")")) fields.companyadd.classList.remove(cfg.class);
    },
    false,
  );

  function validateFields(e) {
    let merchants = [];
    let assignees = [];
    let regulators = [];
    let assets = [];

    for (let cm in companies) {
      if (!cm.endsWith(")")) merchants.push(cm);
    }

    for (let ca in companies) {
      let ass = companies[ca];
      if (ass.info && ass.info.assignees && ass.info.assignees > 0)
        assignees.push(ca);
    }

    for (let cr in companies) {
      let reg = companies[cr];
      if (reg.info && reg.info.regulators && reg.info.regulators > 0)
        regulators.push(cr);
    }

    if (
      fields.brand.value === "" ||
      fields.urllist.value === "" ||
      merchants.length === 0
    ) {
      lib.each([fields.brand, fields.urllist], function (f) {
        if (f.value === "") {
          e.preventDefault();
          f.classList.add(cfg.class);
        } else f.classList.remove(cfg.class);
      });

      if (merchants.length === 0) {
        e.preventDefault();
        fields.companyadd.classList.add(cfg.class);
      } else fields.companyadd.classList.remove(cfg.class);
    }

    // service type value is 'not classified'
    if (fields.classification.value === "none" && showClaModal) {
      e.preventDefault();

      var modalCla = {
        header: lang.servicetype,
        message: lang.noclassification,
        buttons: {},
        callback: function (confirm) {
          if (confirm == 1) {
            showClaModal = false;
          } else {
            fields.classification.classList.add(cfg.class);
          }
        },
      };
      modalCla.buttons[lang.OK] = 1;
      modalCla.buttons[lang.cancel] = "";
      lib.modal(modalCla);
    } else {
      fields.classification.classList.remove(cfg.class);
    }

    // no media, or video without accompanying screenshots, have been added
    if (testtype.value !== "careline") {
      for (let i = 0; i < media.children.length; i++) {
        if (media.children[i].tagName === "LI") assets.push(media.children[i]);
      }

      // no assets
      if (assets.length < 1) {
        e.preventDefault();
        newmedia.classList.add(cfg.class);
      }

      // videos only
      else if (
        showMedModal &&
        assets.length === 1 &&
        assets[0].dataset &&
        assets[0].dataset.type === "video"
      ) {
        e.preventDefault();

        var modalMed = {
          header: lang.missing + " " + lang.media,
          message: lang.noimages,
          buttons: {},
          callback: function (confirm) {
            if (confirm == 1) {
              showMedModal = false;
            } else {
              newmedia.classList.add(cfg.class);
            }
          },
        };
        modalMed.buttons[lang.OK] = 1;
        modalMed.buttons[lang.cancel] = "";
        lib.modal(modalMed);
      } else newmedia.classList.remove(cfg.class);
    } else newmedia.classList.remove(cfg.class);

    // assignee field is empty
    if (
      showAssModal &&
      fields.assignee.value === "" &&
      assignees.length > 0 &&
      fields.status[fields.status.selectedIndex].value > 1000
    ) {
      // var confirmAssignee = confirm(cfg.msgAssignee);
      // if (!confirmAssignee) {
      //   e.preventDefault();
      //   fields.assignee.classList.add(cfg.class);
      // }
      e.preventDefault();

      var modalAss = {
        header: lang.missing + " " + lang.assignee,
        message: lang.noassignee,
        buttons: {},
        callback: function (confirm) {
          if (confirm == 1) {
            showAssModal = false;
          } else {
            fields.assignee.classList.add(cfg.class);
          }
        },
      };
      modalAss.buttons[lang.OK] = 1;
      modalAss.buttons[lang.cancel] = "";
      lib.modal(modalAss);
    } else fields.assignee.classList.remove(cfg.class);

    // sec-assignee fields are the same as assignee/regulator fields
    if (showSecAssModal1 && lib.queryAll('[id^="secassignee-"]').length) {
      Array.from(lib.queryAll('[id^="secassignee-"]')).forEach(function (a) {
        if (
          a.value &&
          (a.value === fields.assignee.value ||
            a.value === fields.regulator.value)
        ) {
          e.preventDefault();

          var modalSecAss = {
            header: lang.wrong + " " + lang.secassignee,
            message: lang.wrongsecassignee,
            buttons: {},
            callback: function (confirm) {
              // if (confirm == 1) {
              // showSecAssModal1 = false;
              // } else {
              a.classList.add(cfg.class);
              // }
            },
          };
          modalSecAss.buttons[lang.OK] = "";
          modalSecAss.buttons[lang.cancel] = "";
          lib.modal(modalSecAss);
        } else a.classList.remove(cfg.class);
      });
    }

    // assignee field is empty but sec-assignee is not
    if (showSecAssModal2 && lib.queryAll('[id^="secassignee-"]').length) {
      Array.from(lib.queryAll('[id^="secassignee-"]')).forEach(function (a) {
        if (a.value !== "" && fields.assignee.value === "") {
          e.preventDefault();

          var modalSecAss2 = {
            header: lang.wrong + " " + lang.secassignee,
            message: lang.notneededsecassignee,
            buttons: {},
            callback: function (confirm) {
              // if (confirm == 1) {
              // showSecAssModal2 = false;
              // } else {
              fields.assignee.classList.add(cfg.class);
              // }
            },
          };
          modalSecAss2.buttons[lang.OK] = "";
          modalSecAss2.buttons[lang.cancel] = "";
          lib.modal(modalSecAss2);
        } else fields.assignee.classList.remove(cfg.class);
      });
    }

    // duplicate sec-assignee name fields
    if (
      showSecAssModal3 &&
      lib.queryAll('[id^="secassignee-"]').length &&
      lib.queryAll('[id^="secassignee-"]').length > 1
    ) {
      const uniqueValues = new Set(
        Array.from(lib.queryAll('[id^="secassignee-"]')).map((s) => s.value),
      );
      // console.log(uniqueValues);

      if (uniqueValues.size < lib.queryAll('[id^="secassignee-"]').length) {
        e.preventDefault();

        var modalSecAss3 = {
          header: lang.wrong + " " + lang.secassignee,
          message: lang.duplicatesecassignee,
          buttons: {},
          callback: function (confirm) {
            // if (confirm == 1) {
            // showSecAssModal1 = false;
            // } else {
            Array.from(lib.queryAll('[id^="secassignee-"]')).forEach(
              function (a) {
                a.classList.add(cfg.class);
              },
            );
            // }
          },
        };
        modalSecAss3.buttons[lang.OK] = "";
        modalSecAss3.buttons[lang.cancel] = "";
        lib.modal(modalSecAss3);
      } else {
        Array.from(lib.queryAll('[id^="secassignee-"]')).forEach(function (a) {
          a.classList.remove(cfg.class);
        });
      }
    }

    // // regulator field is empty
    if (
      showRegModal &&
      fields.regulator.value === "" &&
      regulators.length > 0 &&
      fields.status[fields.status.selectedIndex].value > 1000
    ) {
      // var confirmRegulator = confirm(cfg.msgRegulator);
      // if (!confirmRegulator) {
      //   e.preventDefault();
      //   fields.regulator.classList.add(cfg.class);
      // }
      e.preventDefault();

      var modalReg = {
        header: lang.missing + " " + lang.regulator,
        message: lang.noregulator,
        buttons: {},
        callback: function (confirm) {
          if (confirm == 1) {
            showRegModal = false;
          } else {
            fields.regulator.classList.add(cfg.class);
          }
        },
      };
      modalReg.buttons[lang.OK] = 1;
      modalReg.buttons[lang.cancel] = "";
      lib.modal(modalReg);
    } else fields.regulator.classList.remove(cfg.class);
  }
})();
