/*
  connect to urldb API, add url to urldb if not already there

  // requires: lib.js
*/
/* global lib */

(function () {
  "use strict";

  var urlFound = false,
    cfg = {
      url: "/urldb/",
      class: "hide",
      vldclass: "invalid",
    },
    fields = {
      url: lib.id("url"),
      added_by: lib.id("added_by"),
      category: lib.id("category"),
      language: lib.id("language"),
      source: lib.id("source"),
      guardian: lib.id("guardian"),
      london: lib.id("london"),
      nepal: lib.id("nepal"),
      search_term: lib.id("search_term"),
      notes: lib.id("url_notes"),
    },
    target = [],
    targetDiv = lib.id("targetdiv"),
    fieldsSection = lib.id("landerform"),
    urlmessage = lib.id("urlmessage"),
    fieldsets = lib.className("landerfields"),
    submitBtn = lib.className("submittest"),
    data;

  // no suppport
  if (
    !submitBtn ||
    !fields.url ||
    !fields.added_by ||
    !fields.category ||
    !fields.language ||
    !fields.source ||
    !fields.guardian ||
    !fields.london ||
    !fields.nepal ||
    !fields.notes ||
    !window.addEventListener ||
    !document.body.classList
  )
    return;

  document.addEventListener("DOMContentLoaded", urlHandler);

  // get urls
  fields.url.addEventListener("change", urlHandler, false);

  // update target on checkbox change
  lib.each([fields.guardian, fields.london, fields.nepal], function (checkbox) {
    checkbox.addEventListener("change", targetHandler, false);
  });

  // post url on test save/publish
  [...submitBtn].forEach((b) => {
    b.addEventListener("click", submitUrl, false);
  });

  function urlHandler() {
    // let id = e.target.id;

    if (fields.url.value !== "") {
      ajaxGetUrls(function (err, response) {
        data = JSON.parse(response);
        if (!err && data) {
          // show lander section
          fieldsSection.classList.remove(cfg.class);

          if (data.length > 0) {
            urlFound = true;

            // show url found message
            urlmessage.classList.remove(cfg.class);

            // hide fields
            for (let i = 0; i < fieldsets.length; i++) {
              fieldsets[i].classList.add(cfg.class);
            }
          } else {
            urlFound = false;

            // show fields
            for (let i = 0; i < fieldsets.length; i++) {
              fieldsets[i].classList.remove(cfg.class);
            }

            // hide url found message
            urlmessage.classList.add(cfg.class);
          }
        }
      });
    } else if (fields.url.value === "") {
      urlFound = false;

      // when empty publisher url field. Hide all
      clearFields();
      fieldsSection.classList.add(cfg.class);
    }
  }

  function targetHandler(e) {
    let c = e.target;

    if (c.checked) target.push(c.name);
    else {
      let index = target.indexOf(c.name);

      if (index > -1) {
        target.splice(index, 1);
      }
    }

    target.sort();
  }

  function submitUrl(e) {
    // all required fields are filled. Post URL
    if (
      !urlFound &&
      fields.url.value !== "" &&
      fields.category.value !== "" &&
      fields.language.value !== "" &&
      fields.source.value !== "" &&
      target.length > 0
    ) {
      ajaxPostUrl(function (err, response) {
        data = JSON.parse(response);

        if (data && !data.success) {
          alert(
            "Url submission failed:" +
              "\n" +
              (data.url ? "url: " + data.url + "\n" : "") +
              (data.category ? "category: " + data.category + "\n" : "") +
              (data.language ? "language: " + data.language + "\n" : "") +
              (data.source ? "source: " + data.source + "\n" : "") +
              (data.target ? "target: " + data.target : ""),
          );
        } else if (
          data &&
          data.fail &&
          data.fail.length &&
          data.fail.length > 0
        ) {
          alert(
            "URL submission failed:" +
              "\n" +
              "url: " +
              data.fail[0] +
              " already submitted",
          );
        } else if (
          data &&
          data.success &&
          data.success.length &&
          data.success.length > 0
        ) {
          clearFields();
          urlmessage.classList.remove(cfg.class);

          for (let i = 0; i < fieldsets.length; i++) {
            fieldsets[i].classList.add(cfg.class);
          }
        } else if (err) {
          alert("Error: " + err);
        }
      });
    }

    // url is filled but required landerfields are empty. Ask for confirmation
    else if (
      !urlFound &&
      fields.url.value !== "" &&
      (fields.category.value !== "" ||
        fields.language.value !== "" ||
        fields.source.value !== "" ||
        target.length > 0)
    ) {
      lib.each([fields.category, fields.language, fields.source], function (f) {
        if (f.value === "") f.classList.add(cfg.vldclass);
        else f.classList.remove(cfg.vldclass);
      });

      if (target.length === 0) targetDiv.classList.add(cfg.vldclass);
      else targetDiv.classList.remove(cfg.vldclass);

      // let answer = confirm('Required fields missing: \nTest will be saved/published without submitting the publisher URL to Lander \n\nProceed?');

      // if (!answer) {
      e.preventDefault();
      e.stopPropagation();
      // }
    }
  }

  function ajaxPostUrl(callback) {
    let params = {
      url: fields.url.value ? fields.url.value.trim() : null,
      added_by: fields.added_by.value ? fields.added_by.value : null,
      category: fields.category.value ? fields.category.value : null,
      language: fields.language.value ? fields.language.value : null,
      target: target.length > 0 ? target : null,
      source: fields.source.value ? fields.source.value : null,
      search_term: fields.search_term.value ? fields.search_term.value : null,
      notes: fields.notes.value ? fields.notes.value.trim() : null,
    };

    // initialise call
    let xhr = new XMLHttpRequest();
    xhr.open("POST", cfg.url);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.timeout = 10000; // timeout in ms, 10 seconds

    xhr.send(JSON.stringify(params));

    // request state change
    xhr.onreadystatechange = function () {
      if (xhr.readyState !== 4) return;

      callback(
        xhr.status === 200 ? false : xhr.status, // error code
        xhr.response || null, // data string
      );
    };
  }

  function ajaxGetUrls(callback) {
    lib.ajax(
      cfg.url + encodeURIComponent(fields.url.value),
      function (err, url, json) {
        data = !err && json ? json : null;

        callback(err, json);
      },
    );
  }

  function clearFields() {
    fields.category.value = "";
    fields.language.value = "";
    fields.source.value = "";
    fields.search_term.value = "";
    fields.notes.value = "";
    fields.guardian.checked = false;
    fields.london.checked = false;
    fields.nepal.checked = false;
    target = [];
    fields.category.classList.remove(cfg.vldclass);
    fields.language.classList.remove(cfg.vldclass);
    fields.source.classList.remove(cfg.vldclass);
    targetDiv.classList.remove(cfg.vldclass);
  }
})();
