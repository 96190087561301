/*
  toggle user's theme preference

  // requires: lib.js
*/
/* global lib */

(function () {
  "use strict";

  var cfg = {
      url: "/theme/",
    },
    toggler = lib.id("themetoggler");

  // no suppport
  if (!toggler || !window.addEventListener || !document.body.classList) return;

  toggler.addEventListener("click", handleClick, false);

  function handleClick() {
    ajaxPostUrl(function (err, res) {
      if (!err) {
        location.reload();
      }
    });
  }

  function ajaxPostUrl(callback) {
    // initialise call
    let xhr = new XMLHttpRequest();
    xhr.open("GET", cfg.url);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.timeout = 10000; // timeout in ms, 10 seconds

    xhr.send();

    // request state change
    xhr.onreadystatechange = function () {
      if (xhr.readyState !== 4) return;

      callback(
        xhr.status === 200 ? false : xhr.status, // error code
        xhr.response || null, // data string
      );
    };
  }
})();
