/*
  Test page company control
  Allows companies to be added and removed from a test

  // requires: lib.js shim-template.js
*/
/* global assure lang lib */

(function () {
  "use strict";

  // global company list
  assure.company = {};

  var cfg = {
      newClass: "new",
    },
    company = {
      add: lib.id("companyadd"),
      template: lib.id("companytemplate"),
    };

  company.form = company.form || {};

  // initialise company add/remove
  if (company.add && company.template) {
    company.acWS = company.add.list
      ? company.add.list.dataset.autocomplete || null
      : null;
    company.container = company.template.parentNode;
    company.outer = company.container.parentNode;
    company.outerDisplay = lib.appliedStyle(company.outer, "display");
    company.input = company.template.content.querySelector("input");

    updateDisplay();

    // events
    company.add.addEventListener("change", newCompany, false);
    company.add.addEventListener("keypress", newCompany, false);
    company.container.addEventListener("click", clickCompany, false);

    // public method to add companies
    assure.addCompany = addCompany;
  }

  // new company added in UI
  function newCompany(e) {
    // cancel enter/tab submit
    if (e.type !== "change") {
      var kc = e.keyCode || e.charCode;
      if (kc === 13 || (company.add.value && kc === 9)) e.preventDefault();
      else return;
    }

    // Firefox issue: addCompany could run before field was updated
    setTimeout(function () {
      addCompany(company.add.value);
    }, 1);
  }

  // add company
  function addCompany(comp) {
    // format company name
    comp = comp
      .replace(/\s+/g, " ")
      .replace(/"|\$|\./g, "")
      .trim();
    if (comp && comp == comp.replace(/\D/g, "")) comp = "";
    if (!comp) return;

    // company already exists?
    var i,
      dup = false;
    for (i in assure.company) {
      dup = dup || lib.similar(comp, i);
    }
    if (dup) return;

    // add new company
    company.add.value = "";
    company.add.focus();
    company.input.value = comp;
    var newCompany = document.importNode(company.template.content, true);
    company.container.appendChild(newCompany);

    var cNode = company.container.lastElementChild;

    // check for new company
    if (cNode && company.acWS) {
      lib.ajax(
        company.acWS + encodeURIComponent(comp),
        function (err, url, list) {
          if (err || !list || !list.length || list.indexOf(comp) < 0) {
            cNode.classList.add(cfg.newClass);
            cNode.setAttribute("title", (lang && lang.companynew) || "");
            assure.company[comp].new = true;
          }

          // raise company add event
          if (CustomEvent) {
            var cEvent = new CustomEvent("company", {
              detail: { type: "add", name: comp },
            });
            document.dispatchEvent(cEvent);
          }
        },
      );
    }

    updateDisplay();

    return cNode;
  }

  // delete company
  function clickCompany(e) {
    e.preventDefault();
    var t = e.target;

    if (t.nodeName == "A" && t.hash == "#delete") {
      // get company
      var c = lib.tag("input", t.parentNode)[0].value;

      // remove input
      company.container.removeChild(t.parentNode);

      // update
      updateDisplay();

      // raise company delete event
      if (CustomEvent) {
        var cEvent = new CustomEvent("company", {
          detail: { type: "remove", name: c },
        });
        document.dispatchEvent(cEvent);
      }
    }
  }

  // check display
  function updateDisplay() {
    // update company list
    var cSet = lib.tag("input", company.container),
      cList = [],
      comp,
      c,
      i;
    for (c = 0; c < cSet.length; c++) {
      comp = cSet[c].value;
      cList.push(comp);
      assure.company[comp] = assure.company[comp] || { new: false };
    }

    // remove deleted
    for (i in assure.company) {
      if (cList.indexOf(i) < 0) delete assure.company[i];
    }

    // show/hide company list
    company.outer.style.display = c > 0 ? company.outerDisplay : "none";
  }
})();
