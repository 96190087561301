/*
  Generic file drag and drop handler
  Used for form uploads

  // requires: lib.js shim-template.js
*/
/* global assure lang lib doaction */

(function () {
  "use strict";

  // global settings
  assure.validate = assure.validate || [];

  // configuration
  var mc = {
    fileInput: lib.query('input[type="file"]'),
    buttons: lib.id("mediabuttons"),
    media: lib.id("media"),
    templateImage: lib.id("mediatemplate-image"),
    templateFile: lib.id("mediatemplate-file"),
    templateVideo: lib.id("mediatemplate-video"),
    templateAlert: lib.id("mediatemplate-alert"),
    counter: lib.query('a[href="#testmedia"] sup'),
    activeClass: "active",
    dragClass: "dragging",
    dragOverTopClass: "dragovertop",
    dragOverEndClass: "dragoverend",
    hoverName: "filehover",
    loader: lib.id("load"),
    loadedClass: "inactive",
    images: lib.queryAll("figure > a > img"),
    bannercheck: lib.queryAll('input[name="bannerimg"]'),
    landingcheck: lib.queryAll('input[name="landingimg"]'),
    landingPageCards: lib.queryAll("[data-landnum]"),
  };

  // form
  mc.form = mc.fileInput
    ? mc.fileInput.form
    : lib.query('form[enctype="multipart/form-data"]');

  // no support or input file field
  if (
    !mc.form ||
    !window.addEventListener ||
    !window.File ||
    !window.FileList ||
    !window.FileReader ||
    !document.body.classList
  )
    return;

  var canAdd = true,
    inProgress = 0,
    navUsed = null,
    saveProgress,
    nexttest,
    landPageNumber = 0,
    imgNum = mc.images ? mc.images.length : 0,
    newImgIdx = 0;

  // record ID
  mc.id = lib.query('input[name="_id"]');
  if (mc.id) mc.id = mc.id.value;

  // hide broken images
  if (mc.images && mc.images.length) {
    mc.images.forEach(function (img) {
      img.onerror = function () {
        if (
          lib.closest("figure", img).nextElementSibling &&
          lib.closest("figure", img).nextElementSibling.nodeName === "FIELDSET"
        ) {
          if (img.getAttribute("alt")) {
            img.parentNode.style.display = "none";
            lib.closest("figure", img).appendChild(document.createElement("p"));
            img.parentNode.nextElementSibling.textContent =
              img.getAttribute("alt");
          } else {
            lib.closest("figure", img).style.display = "none";
          }
        } else {
          lib.closest("li", img).style.display = "none";
        }
      };
    });
  }

  // get tests array
  var testArray = sessionStorage.getItem("testids")
    ? JSON.parse(sessionStorage.getItem("testids"))
    : [];

  // find next test Id
  var currentindex = testArray.indexOf(mc.id);
  if (currentindex >= 0 && currentindex < testArray.length - 1) {
    nexttest = testArray[currentindex + 1];
  }

  // set test to prefetch/prerender
  if (nexttest) {
    // sessionStorage.setItem('listprefetch', '/test/' + nexttest);
    doaction.redirect = "/test/" + nexttest;
  }

  // remove NEXT (skip) button when opened in new tab
  if (testArray.length === 0) {
    lib.each(
      lib.queryAll('[type="submit"][name="doaction"][value="skip"]'),
      function (b) {
        b.style.display = "none";
      },
    );
  }

  // media control
  if (mc.fileInput && mc.media) {
    mc.media.classList.add(mc.activeClass);
    mc.added = lib.className("added", mc.media);
    mc.mediaDisplay = lib.appliedStyle(mc.media, "display");
    updateDisplay();

    // image file template
    mc.image = mc.templateImage.content.querySelector("img");

    // video file template
    mc.video = mc.templateVideo.content.querySelector("span");

    // other file
    mc.file = mc.templateFile.content.querySelector("span");

    // image-template checkboxes
    mc.templateBannercheck = mc.templateImage.content.querySelector(
      'input[name="bannerimg"]',
    );
    mc.templateLandingcheck = mc.templateImage.content.querySelector(
      'input[name="landingimg"]',
    );

    // append file hover element
    var fh = document.createElement("div");
    fh.id = mc.hoverName;
    fh.textContent = "\ue845";
    mc.hoverElement = document.body.appendChild(fh);

    // file browse and choose events
    document.body.addEventListener("fileStart", fileStart, false);
    document.body.addEventListener("dragover", fileHover, false);
    document.body.addEventListener("dragleave", fileHover, false);
    document.body.addEventListener("drop", fileSelect, false);
    mc.fileInput.addEventListener("change", fileSelect, false);

    // mouse element events
    mc.media.addEventListener("mousedown", dragStart, false);
    mc.media.addEventListener("mousemove", dragMove, false);
    mc.media.addEventListener("mouseover", dragOver, false);
    document.body.addEventListener("mouseup", dragStop, false);

    // button add events
    if (mc.buttons) {
      mc.buttons.addEventListener("click", buttonEvent, false);
    }

    // click event (deletions)
    mc.media.addEventListener("click", clickEvent, false);
  }

  // Number of landing page images
  if (mc.landingPageCards) {
    landPageNumber = mc.landingPageCards.length;
  }

  // banners/landing images checkboxs events
  if (mc.bannercheck || mc.landingcheck) {
    mc.bannercheck.forEach((el) => {
      el.addEventListener("change", imgChecked, false);
    });

    mc.landingcheck.forEach((el) => {
      el.addEventListener("change", imgChecked, false);
    });
  }

  function imgChecked(e) {
    let t = e.target;
    let imgCard = t.closest("li");
    // is this a new, unsaved yet, image?
    let newImage =
      imgCard.hasAttribute("data-originalimagename") &&
      imgCard.hasAttribute("data-originalimagetype");

    let newImageIdx = newImage && Number(imgCard.dataset.newimageidx);

    let imagename = t.closest("li").querySelector('input[name="medianame"]');

    let imagetype = newImage
      ? imgCard.dataset.originalimagetype.split("/")[1].replace("e", "")
      : t.closest("li").querySelector('input[name="mediaext"]').value;

    if (t.name == "bannerimg") {
      let landcheckbox = t.parentNode.nextElementSibling.querySelector(
        'input[name="landingimg"]',
      );

      if (t.checked) {
        let replace = `(\.${imagetype})$`;
        let re = new RegExp(replace, "g");

        // rename banner
        imagename.value = imagename.value
          .replace(/land(\d+)/g, "")
          .replace(re, `ad01.${imagetype}`);

        // same for new images
        if (newImage) {
          mc.added[newImageIdx].fileAdded.newname =
            imgCard.dataset.originalimagename
              .replace(/land(\d+)/g, "")
              .replace(re, `ad01.${imagetype}`);
        }

        // this image can only be a banner.
        if (landcheckbox && landcheckbox.checked) {
          landcheckbox.checked = false;
          landPageNumber--;
          delete imgCard.dataset.landnum;
        }

        // there can be only one... banner
        mc.bannercheck.forEach((other) => {
          let otherimagename = other
            .closest("li")
            .querySelector('input[name="medianame"]');

          if (other != t && other.checked) {
            other.checked = false;
            otherimagename.value = otherimagename.value
              .replace(/ad(\d+)/g, "")
              .replace(/ad|banner/g, "");

            // also if 'other' belongs to a new image
            let newCheckedBanner = other
              .closest("li")
              .hasAttribute("data-newimageidx");

            if (newCheckedBanner) {
              let bannerIdx = other.closest("li").dataset.newimageidx;
              delete mc.added[Number(bannerIdx)].fileAdded.newname;
            }
          }
        });
      } else {
        imagename.value = imagename.value
          .replace(/ad(\d+)/g, "")
          .replace(/ad|banner/g, "")
          .replace(/land(\d+)/g, "");

        if (newImage) {
          mc.added[newImageIdx].fileAdded.newname =
            imgCard.dataset.originalimagename
              .replace(/ad(\d+)/g, "")
              .replace(/ad|banner/g, "")
              .replace(/land(\d+)/g, "");
        }
      }
    } else if (t.name == "landingimg") {
      let adcheckbox = t.parentNode.previousElementSibling.querySelector(
        'input[name="bannerimg"]',
      );

      if (t.checked) {
        landPageNumber++;
        let replace = `(\.${imagetype})$`;
        let re = new RegExp(replace, "g");

        // rename banner
        imagename.value = imagename.value
          .replace(/ad(\d+)/g, "")
          .replace(/ad|banner/g, "")
          .replace(re, `land0${landPageNumber}.${imagetype}`);

        // add data attribute to LI element, so we know this is a landing-page
        imgCard.dataset.landnum = landPageNumber;

        // this image can only be a landing-page image.
        if (adcheckbox && adcheckbox.checked) adcheckbox.checked = false;

        // same for new images
        if (newImage) {
          mc.added[newImageIdx].fileAdded.newname =
            imgCard.dataset.originalimagename
              .replace(/ad(\d+)/g, "")
              .replace(/ad|banner/g, "")
              .replace(re, `land0${landPageNumber}.${imagetype}`);
        }
      } else {
        landPageNumber--;

        imagename.value = imagename.value
          .replace(/land(\d+)/g, "")
          .replace(/ad(\d+)/g, "")
          .replace(/ad|banner/g, "");

        if (newImage) {
          mc.added[newImageIdx].fileAdded.newname =
            imgCard.dataset.originalimagename
              .replace(/land(\d+)/g, "")
              .replace(/ad(\d+)/g, "")
              .replace(/ad|banner/g, "");
        }

        // remove data attribute from LI element
        delete imgCard.dataset.landnum;
      }
    }
  }

  // form submit
  mc.form.addEventListener("submit", formSubmit, false);

  // detect submit button navigation
  mc.nav = lib.query("nav.submit", mc.form);
  if (mc.nav) {
    mc.nav.addEventListener(
      "click",
      function (e) {
        navUsed = lib.closest("button", e.target);
      },
      false,
    );
  }

  // prefetch/render next page
  // var
  //   prefetch = sessionStorage.getItem('listprefetch'),
  //   head = lib.tag('head');

  // if (prefetch && head.length) {

  // var
  //   link1 = document.createElement('link'),
  //   link2 = document.createElement('link');

  // head = head[0];
  // prefetch = location.origin + prefetch;

  // link1.setAttribute('rel', 'prefetch');
  // link2.setAttribute('rel', 'prerender');

  // link1.setAttribute('href', prefetch);
  // link2.setAttribute('href', prefetch);

  // head.appendChild(link1);
  // head.appendChild(link2);

  // sessionStorage.removeItem('listprefetch');
  // }

  // dragging start
  function dragStart(e) {
    var t = e.target;
    if (canAdd && !mc.drag && t.classList.contains("move")) {
      // move started
      mc.drag = {
        item: lib.closest("li", t),
        x: e.clientX,
        y: e.clientY,
      };

      mc.media.classList.add(mc.dragClass);
      mc.drag.item.classList.add(mc.dragClass);
    } else if (
      t.nodeName == "IMG" ||
      t.nodeName == "A" ||
      t.nodeName == "VIDEO"
    ) {
      // prevent dragging on images, links and videos
      e.preventDefault();
    }
  }

  // dragging move
  function dragMove(e) {
    if (!mc.drag) return;
    mc.drag.item.style.left = e.clientX - mc.drag.x + "px";
    mc.drag.item.style.top = e.clientY - mc.drag.y + "px";
    dragOnItem(e, mc.drag.over);
  }

  // dragging over
  function dragOver(e) {
    if (!mc.drag) return;
    dragOnItem(e, lib.closest("li", e.target));
  }

  function dragOnItem(e, li) {
    // remove any selections
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    if (li != mc.drag.over) {
      // remove old styles
      if (mc.drag.over && mc.drag.overclass) {
        mc.drag.over.classList.remove(mc.drag.overclass);
      }

      mc.drag.over = li;
      mc.drag.overclass = null;
    }

    if (mc.drag.over) {
      var ot =
        e.clientX <
        mc.drag.over.offsetWidth / 2 +
          lib.posLeft(mc.drag.over) -
          window.pageXOffset
          ? mc.dragOverTopClass
          : mc.dragOverEndClass;

      if (mc.drag.overclass && ot != mc.drag.overclass) {
        mc.drag.over.classList.remove(mc.drag.overclass);
      }

      mc.drag.overclass = ot;
      mc.drag.over.classList.add(ot);
    }
  }

  // dragging stop
  function dragStop() {
    if (!mc.drag) return;

    // remove dragging classes
    mc.media.classList.remove(mc.dragClass);
    mc.drag.item.classList.remove(mc.dragClass);
    mc.drag.item.style.left = "0px";
    mc.drag.item.style.top = "0px";

    if (mc.drag.over) {
      mc.drag.over.classList.remove(mc.drag.overclass);

      // move item in DOM
      if (mc.drag.over != mc.drag.item) {
        var sib =
          mc.drag.overclass == mc.dragOverTopClass
            ? mc.drag.over
            : mc.drag.over.nextElementSibling;

        if (sib) {
          mc.media.insertBefore(mc.drag.item, sib);
        } else {
          mc.media.appendChild(mc.drag.item);
        }

        // reset added images indexes, checkboxes and names
        let allAddedImgs = mc.media.querySelectorAll("[data-newimageidx]");

        for (let i = 0; i < allAddedImgs.length; i++) {
          allAddedImgs[i].dataset.newimageidx = i;
        }

        for (let m = 0; m < mc.added.length; m++) {
          delete mc.added[m].fileAdded.newname;
        }
        let newBanners = mc.media.querySelectorAll(
          'li.added > fieldset > div > input[name="bannerimg"]',
        );
        let newLandings = mc.media.querySelectorAll(
          'li.added > fieldset > div > input[name="landingimg"]',
        );
        newBanners.forEach((el) => {
          el.checked = false;
        });
        newLandings.forEach((el) => {
          el.checked = false;
        });
      }
    }

    mc.drag = null;
  }

  // disable drag start
  function fileStart(e) {
    e.preventDefault();
  }

  // update hover styles
  function fileHover(e) {
    e.preventDefault();
    if (!canAdd || mc.drag) return;

    // file drag/leave
    if (e.type === "dragover" && e.target !== mc.hoverElement) {
      document.body.classList.add(mc.hoverName);
    } else if (e.type === "dragleave" && e.target === mc.hoverElement) {
      document.body.classList.remove(mc.hoverName);
    }
  }

  // files dropped/selected
  function fileSelect(e) {
    e.preventDefault();
    document.body.classList.remove(mc.hoverName);
    if (!canAdd || mc.drag) return;

    // ensure no further adding during processing
    canAdd = false;

    var i,
      f,
      ft,
      ext,
      li,
      dup,
      fa,
      m,
      err,
      errlist = document.createElement("ol"),
      file = e.target.files || e.dataTransfer.files;

    for (i = 0; (f = file[i]); i++) {
      // find file type
      ext = null;
      for (ft in assure.filetype) {
        if (f.type == assure.filetype[ft].mime) {
          ext = ft;
          break;
        }
      }

      // find duplicates
      dup = false;
      for (m = 0; !dup && m < mc.added.length; m++) {
        fa = mc.added[m].fileAdded;
        dup =
          f.name == fa.name &&
          f.lastModified == fa.lastModified &&
          f.size == fa.size &&
          f.type == fa.type;
      }

      // check for errors
      err = null;

      if (dup) {
        // duplicate file added
        err = f.name + " (" + lang.mediafailduplicate + ")";
      } else if (inProgress + mc.added.length >= assure.fileupload.maxfiles) {
        // too many files added
        err = f.name + " (" + lang.mediafailnumber + ")";
      } else if (!ext) {
        // file type not permitted
        err = f.name + " (" + lang.mediafailtype + ")";
      } else if (f.size > assure.fileupload.maxsize) {
        // file too large
        err = f.name + " (" + lang.mediafailsize + ")";
      } else {
        inProgress++;

        if (assure.filetype[ext].type == "image") {
          // add new image
          addImage(f);
        } else if (assure.filetype[ext].type == "video") {
          // add new video
          addVideo(f, "vid");
        } else {
          // add other file type
          addFile(f, ext);
        }
      }

      // error - file not added
      if (err) {
        li = errlist.appendChild(document.createElement("li"));
        li.textContent = err;
        errlist.appendChild(li);
      }
    }

    // clear input box
    mc.fileInput.value = "";

    // show error(s)
    if (errlist.children.length) {
      var modal = {
          header: lang.mediaadd,
          message: document.createDocumentFragment(),
          buttons: {},
          callback: function () {
            canAdd = true;
          },
        },
        p = document.createElement("p");

      p.textContent = lang.mediafail;
      modal.message.appendChild(p);
      modal.message.appendChild(errlist);
      modal.buttons[lang.OK] = 0;
      lib.modal(modal);
    } else {
      canAdd = true;
    }
  }

  // add image to upload list
  function addImage(file) {
    var reader = new FileReader();
    reader.onload = function (r) {
      inProgress--;

      // pass image name to html
      let imgCard = mc.templateImage.content.querySelector("li");
      imgCard.dataset.originalimagename = file.name;
      imgCard.dataset.originalimagetype = file.type;
      imgCard.dataset.newimageidx = newImgIdx;

      // initialise banner/landing checkboxes
      mc.image.src = r.target.result;
      mc.templateBannercheck.setAttribute("id", `bannerimg-${imgNum}`);
      mc.templateBannercheck.nextElementSibling.setAttribute(
        "for",
        `bannerimg-${imgNum}`,
      );
      mc.templateLandingcheck.setAttribute("id", `landingimg-${imgNum}`);
      mc.templateLandingcheck.nextElementSibling.setAttribute(
        "for",
        `landingimg-${imgNum}`,
      );
      var newImg = document.importNode(mc.templateImage.content, true);
      mc.media.appendChild(newImg);
      mc.added[mc.added.length - 1].fileAdded = file;

      // update the checkboxes lists
      mc.bannercheck = lib.queryAll('input[name="bannerimg"]');
      mc.landingcheck = lib.queryAll('input[name="landingimg"]');

      mc.bannercheck.forEach((el) => {
        el.addEventListener("change", imgChecked, false);
      });

      mc.landingcheck.forEach((el) => {
        el.addEventListener("change", imgChecked, false);
      });
      imgNum++; // add 1 to total number of media images
      newImgIdx++; // add 1 to number of NEW media images

      triggerAddEvent();
      updateDisplay();
      assure.textarea.init();
    };
    reader.readAsDataURL(file);
  }

  // add video to upload list
  function addVideo(file, ext) {
    inProgress--;

    mc.video.textContent = file.name;
    mc.video.className = "icon-file-" + ext.substr(0, 3);
    var newFile = document.importNode(mc.templateVideo.content, true);
    mc.media.appendChild(newFile);
    mc.added[mc.added.length - 1].fileAdded = file;
    triggerAddEvent();
    updateDisplay();
    assure.textarea.init();
  }

  // add another file type
  function addFile(file, ext) {
    inProgress--;

    mc.file.textContent = file.name;
    mc.file.className = "icon-file-" + ext.substr(0, 3);
    var newFile = document.importNode(mc.templateFile.content, true);
    mc.media.appendChild(newFile);
    mc.added[mc.added.length - 1].fileAdded = file;
    triggerAddEvent();
    updateDisplay();
    assure.textarea.init();
  }

  // react to command buttons
  function buttonEvent(e) {
    e.preventDefault();
    e.stopPropagation();

    var t = lib.closest("a", e.target);
    if (!t) return;

    switch (t.hash) {
      case "#newalert":
        var newAlert = document.importNode(mc.templateAlert.content, true);
        mc.media.appendChild(newAlert);
        triggerAddEvent();
        break;
    }

    updateDisplay();
    assure.textarea.init();
    mc.media.lastElementChild.querySelector("textarea").focus();
  }

  // trigger a file add event
  function triggerAddEvent() {
    if (!CustomEvent || mc.media.children.length <= 0) return;

    var fEvent = new CustomEvent("file", {
      detail: {
        type: "add",
        element: mc.media.children[mc.media.children.length - 1],
      },
    });
    document.dispatchEvent(fEvent);
  }

  // handle button clicks
  function clickEvent(e) {
    var t = e.target;
    if (t.nodeName == "A") {
      if (t.hash == "#delete") {
        e.preventDefault();
        e.stopPropagation();
        mc.media.removeChild(lib.closest("li", t));
        updateDisplay();
      } else if (t.hash == "#move") {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  // submit form
  function formSubmit(e) {
    e.preventDefault();

    if (!canAdd) return;
    canAdd = false;
    mc.wscall = 0;
    var skip = false;

    if (navUsed && navUsed.name == "doaction") {
      // delete action - confirm
      if (navUsed.value == "delete") {
        var modal = {
          header: lang.delete,
          message: lang.sure,
          buttons: {},
          callback: startSubmit,
        };
        modal.buttons[lang.OK] = 1;
        modal.buttons[lang.cancel] = "";
        lib.modal(modal);
        if (assure.form) assure.form.cancelSubmit();
        return;
      }

      // publish first?
      if (navUsed.value == "publish") {
        var pub = lib.id("published");
        if (
          pub &&
          pub.nodeName == "INPUT" &&
          pub.type == "checkbox" &&
          !pub.checked
        ) {
          pub.checked = true;
        }
      }

      // skip test
      skip = navUsed.value == "skip";
    }

    // validation
    if (!skip) {
      var v = 0,
        go = true;
      while (go && v < assure.validate.length) {
        go = assure.validate[v]();
        v++;
      }

      // validation failed
      if (!go) {
        canAdd = true;
        if (assure.form) assure.form.cancelSubmit();
        return;
      }
    }

    if (skip && mc.loader && mc.loader.classList)
      mc.loader.classList.remove(mc.loadedClass);

    // submit
    if (!skip) startSubmit(1);
    // next
    else if (skip) {
      // disable unload event
      window.onbeforeunload = function () {
        return undefined;
      };
      redirect();
    }
  }

  // initiate submit
  function startSubmit(start) {
    if (!start) {
      canAdd = true;
      return;
    }

    // create progress modal
    var modal = {
      className: "progress",
      header: lang.updating,
      message: document.createElement("progress"),
      nocancel: true,
    };
    modal.message.max = 100;
    modal.message.value = 0;
    lib.modal(modal);

    // progress element
    saveProgress = lib.query("#modal progress");

    // start Ajax request
    mc.wscall++;
    lib.ajax(
      mc.form,
      submitComplete,
      appendData,
      showProgress,
      mc.added.length * 60000,
    );
  }

  // append form data
  function appendData(fd) {
    // append submit button
    var addFiles = true;
    if (navUsed) {
      fd.append(navUsed.name, navUsed.value);
      addFiles = !(navUsed.name == "doaction" && navUsed.value == "delete");
      navUsed = null;
    }

    // append files (if not deleting)
    for (var f = 0; addFiles && f < mc.added.length; f++) {
      let newName = mc.added[f].fileAdded.newname; // image needs to  be renamed
      fd.append(
        "file",
        mc.added[f].fileAdded,
        newName || mc.added[f].fileAdded.name,
      );
    }

    return fd;
  }

  // show progress
  function showProgress(p) {
    if (saveProgress) {
      saveProgress.value = Math.round((p.loaded / p.total) * 100);
    }
  }

  // form submitted
  function submitComplete(err, url, data) {
    console.log("Ajax err:", err);
    console.log("Ajax url:", url);
    console.log("Ajax data:", data);

    mc.wscall--;

    if (err) {
      // close progress dialog
      lib.modalClose();

      setTimeout(function () {
        // show error dialog
        var modal = {
          header: lang.error,
          message: lang.testerror + " (" + err + ")",
          buttons: {},
          callback: function () {
            canAdd = true;
          },
        };
        modal.buttons[lang.OK] = 0;

        lib.modal(modal);
      }, 350);
    } else {
      // redirect
      redirect();
    }
  }

  // redirect action
  function redirect() {
    // web services still waiting?
    if (mc.wscall > 0) return;

    if (doaction.redirect) {
      // next item
      window.location.href = doaction.redirect;
    } else {
      // test list
      var args = sessionStorage.getItem("listargs") || "";
      window.location.href = doaction.list + (args ? "?" + args : "");
    }
  }

  // check display
  function updateDisplay() {
    var count = mc.media.childElementCount - 4;
    mc.media.style.display = count > 0 ? mc.mediaDisplay : "none";
    if (mc.counter) mc.counter.textContent = count;
  }
})();
