/*
  Test list filter changes

  // requires: lib.js test-company.js
*/
/* global lib */

(function () {
  "use strict";

  var filter = {
      complete: lib.id("complete"), // complete drop-down
      datefrom: lib.id("datefrom"), // datefrom
      dateto: lib.id("dateto"), // dateto
    },
    testlistCsv = lib.id("testlistCsv");

  // no support or functionality not required
  if (
    !window.addEventListener ||
    !filter.complete ||
    !filter.datefrom ||
    !filter.dateto
  )
    return;

  if (testlistCsv) {
    document.addEventListener("DOMContentLoaded", function () {
      setTimeout(() => {
        testlistCsv.href =
          "/test/list/" +
          (location.search ? location.search : "?published=1") +
          "&format=csv";
      }, 50);
    });
  }

  // change complete drop-down
  filter.complete.addEventListener("change", completeChange, false);
  filter.datefrom.addEventListener("change", dateChange, false);
  filter.dateto.addEventListener("change", dateChange, false);

  // reset dates if 28-days is chosen
  function completeChange() {
    if (!filter.complete.selectedIndex) {
      filter.datefrom.value = "";
      filter.dateto.value = "";
    }
  }

  // set all time if date is chosen
  function dateChange() {
    if (filter.datefrom.value || filter.dateto.value) {
      lib.setSelect(filter.complete, "0");
    }
  }
})();
