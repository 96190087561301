/*
	Assure client-side configuration
*/

// TODO these are all hard-coded now, gulp will not resolve the imports.

// global variable
var assure = {
  fileupload: {
    maxfiles: 20,
    maxsize: 15000000,
  },
  autocomplete: parseInt("/* @echo cfg.autocomplete */", 10),
  analytics: "/* @if live *//* @echo cfg.analytics *//* @endif */",
};

// file types
assure.filetype = {
  csv: { type: "CSV", mime: "text/csv" },
  doc: { type: "Microsoft Word", mime: "application/msword" },
  docx: {
    type: "Microsoft Word",
    mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  gif: { type: "image", mime: "image/gif" },
  htm: { type: "HTML", mime: "text/html" },
  html: { type: "HTML", mime: "text/html" },
  jpg: { type: "image", mime: "image/jpeg" },
  jpeg: { type: "image", mime: "image/jpeg" },
  m4v: { type: "video", mime: "video/mp4" },
  mp4: { type: "video", mime: "video/mp4" },
  odp: {
    type: "OpenDocument Presentation",
    mime: "application/vnd.oasis.opendocument.presentation",
  },
  ods: {
    type: "OpenDocument Spreadsheet",
    mime: "application/vnd.oasis.opendocument.spreadsheet",
  },
  odt: {
    type: "OpenDocument Text",
    mime: "application/vnd.oasis.opendocument.text",
  },
  pdf: { type: "Adobe PDF", mime: "application/pdf" },
  png: { type: "image", mime: "image/png" },
  ppt: { type: "Microsoft PowerPoint", mime: "application/vnd.ms-powerpoint" },
  pptx: {
    type: "Microsoft PowerPoint",
    mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  rtf: { type: "RTF", mime: "application/rtf" },
  txt: { type: "text", mime: "text/plain" },
  xls: { type: "Microsoft Excel", mime: "application/vnd.ms-excel" },
  xlsx: {
    type: "Microsoft Excel",
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  zip: { type: "ZIP", mime: "application/zip" },
};
