/*
  Auto-complete network field
  Network selected when a company is added or removed

  // requires: lib.js test-company.js
*/
/* global lib */

(function () {
  "use strict";

  // settings
  var network = {
    readonly: !!lib.id("readonly"), // readonly page
    field: lib.id("network"), // network field
    class: "autofill", // change class
  };

  // no support or functionality not required
  if (
    network.readonly ||
    !network.field ||
    !lib.id("companyadd") ||
    !window.addEventListener
  )
    return;

  // added stack
  var cStack = [],
    manChange = false,
    reMNO = /\s*\(MNO\)$/i;

  // network change event
  network.field.addEventListener(
    "change",
    function () {
      manChange = !!network.field.selectedIndex;
      network.field.classList.remove(network.class);
    },
    false,
  );

  // company change event
  document.addEventListener(
    "company",
    function (e) {
      var cName = e.detail.name.trim(),
        company = cName.replace(reMNO, ""),
        cItem = network.field.querySelector(
          '[data-name="' + lib.normalise(company) + '"]',
        );

      cItem = (cItem && cItem.value) || null;

      if (!cItem || company === cName) return;

      if (e.detail.type === "add") {
        // add company
        if (network.field.value === "xx") {
          lib.setSelect(network.field, cItem);
          network.field.classList.add(network.class);
        }
        cStack.push(cItem);
      } else if (e.detail.type === "remove") {
        // remove company
        var p = cStack.indexOf(cItem);

        if (p >= 0) {
          // remove network
          cStack.splice(p, 1);

          // change network
          if (!p && !manChange) {
            lib.setSelect(network.field, cStack[0] || "xx");
          }
        }
      }
    },
    false,
  );
})();
