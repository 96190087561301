/*
  toggle a class on the body
  set data-open="classname" on any element to add that class to the body and retain state

  // requires: lib.js
*/
/* global lib */

(function () {
  "use strict";

  // configuration
  var cfg = {
      toggler: "toggler",
    },
    b = document.body,
    store,
    lsEnabled = true;

  try {
    store = localStorage.getItem(cfg.toggler);
  } catch (e) {
    lsEnabled = false;
  }

  // classList and dataset support?
  if (!b.classList || !b.dataset) return;

  // fetch initial state
  if (store) {
    store = JSON.parse(store);

    var i,
      name,
      set,
      tog = lib.className(cfg.toggler),
      qs = lib.queryStringParse();

    for (i = 0; i < tog.length; i++) {
      name = tog[i].dataset && tog[i].dataset.open;

      // force or querystring override
      if (name && (tog[i].dataset.force || qs[name])) {
        set = parseInt(tog[i].dataset.force || qs[name] || 0, 10) || 0;
        if (set) store[name] = true;
        else delete store[name];
      }
    }

    // set state
    for (i in store) {
      if (store[i]) b.classList.add(i);
    }
  } else {
    store = {};
  }

  // store state on unload
  if (lsEnabled) {
    window.addEventListener(
      "unload",
      function () {
        localStorage.setItem(cfg.toggler, JSON.stringify(store));
      },
      false,
    );
  }

  // click event attached to body
  b.addEventListener("click", toggle, false);

  // toggle item
  function toggle(e) {
    var t = e.target,
      name = t.dataset && t.dataset.open;
    if (name && t.classList.contains(cfg.toggler)) {
      // toggler clicked
      if (store[name]) delete store[name];
      else store[name] = true;

      // add/remove class
      if (store[name]) b.classList.add(name);
      else b.classList.remove(name);
    }
  }
})();
