/*
  Copy from input to clipboard

  // requires: lib.js
*/
/* global lib */

(function () {
  "use strict";

  // configuration
  var cfg = {
    activeClass: "active",
  };

  // activate copyable elements
  lib.each(lib.queryAll("label.copyable"), function (c) {
    if (lib.id(c.htmlFor)) {
      c.classList.add(cfg.activeClass);
      c.addEventListener("click", copy, false);
    }
  });

  // copy input/textarea text
  function copy(e) {
    var field = lib.id(e.target.htmlFor);

    if (field) {
      if (field.select && field.value) {
        // form input
        field.select();
        try {
          document.execCommand("copy");
        } catch (err) {}
      } else if (window.getSelection) {
        // DOM element
        try {
          var range = document.createRange();
          range.selectNode(field);
          window.getSelection().addRange(range);
          document.execCommand("copy");
        } catch (err) {}
      }
    }
  }
})();
