/*
  SelectColor
  changes a <select> background-color according to selected item

  // requires: lib.js
*/
/* global lib */

(function () {
  "use strict";

  var select = lib.className("selectcolor");
  for (var s = 0; s < select.length; s++) {
    if (select[s].nodeName == "SELECT") {
      select[s].addEventListener("change", selectChange, false);
      selectChange(select[s]);
    }
  }

  // select changed
  function selectChange(s) {
    s = s.target || s;
    var c = lib.appliedStyle(s.options[s.selectedIndex], "border-top-color");
    s.style.backgroundColor = c || "transparent";
  }
})();
