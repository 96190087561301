/*
  Auto-complete boxes
  Extends standard datalist control

  // requires: lib.js
*/
/* global assure lib */

(function () {
  "use strict";

  // configuration
  var cfg = {
    datalist: "datalist[data-autocomplete]",
    minlength: 1,
    debounce: 300,
    max: assure.autocomplete,
  };

  // activate datalist elements
  lib.each(lib.queryAll(cfg.datalist), function (d) {
    // find related input
    var input = lib.query('input[list="' + d.id + '"]');
    if (input && input.list.dataset.autocomplete) {
      input.type = "search"; // prevents some Chrome quirkiness
      // input.form.autocomplete = 'off'; // quirky in Chrome when auto-fill enabled
      lib.eventDebounce(input, "keyup", autoComplete, cfg.debounce);
    }
  });

  // handle autocomplete event
  function autoComplete(e) {
    var search = e.target.value.trim().toLowerCase(),
      datalist = e.target.list,
      last = datalist.dataset.acSearch,
      count = parseInt(datalist.dataset.acCount || 0, cfg.max);

    if (
      search.length < cfg.minlength ||
      last === search ||
      (search.indexOf(last) === 0 && count < cfg.max)
    )
      return;

    // wipe invalid results
    if (search.indexOf(last) !== 0) lib.empty(datalist);

    // store new search
    datalist.dataset.acSearch = search;

    // ajax call
    lib.ajax(
      datalist.dataset.autocomplete + encodeURIComponent(search),
      function (err, url, list) {
        list = list || [];
        var resList = list.join(";"),
          lastRes = datalist.dataset.acResult || "";

        datalist.dataset.acResult = resList;
        datalist.dataset.acCount = list.length;

        // no change
        if (err || !resList || lastRes.indexOf(resList) >= 0) return;

        // create DOM options
        var i,
          opt,
          newList = document.createDocumentFragment();
        for (i = 0; i < list.length && i < cfg.max; i++) {
          opt = newList.appendChild(document.createElement("option"));
          opt.value = list[i];
          opt.textContent = list[i];
        }

        // empty and repopulate datalist
        lib.empty(datalist);
        datalist.appendChild(newList);
      },
    );
  }
})();
