/*
  dashboard map

  // requires: lib.js
*/
/* global lang lib map */

setTimeout(function () {
  "use strict";

  var svg = lib.query("svg.map");
  if (!svg || typeof map == "undefined") return;

  var cont = svg.parentNode,
    colOn = lib
      .appliedStyle(svg, "border-top-color")
      .replace("rgb", "rgba")
      .replace(")", ",X)"),
    colOff = colOn.replace("X", ".3"),
    pin = {
      active: null,
      over: false,
    },
    cc,
    pe;

  colOn = colOn.replace("X", ".8");

  // set map colours
  for (cc in map) {
    map[cc].path = lib.id(cc);
    if (map[cc].path && (map[cc].mytot || map[cc].gmtot)) {
      map[cc].path.style.fill = map[cc].mytot || map[cc].gmlnk ? colOn : colOff;
    } else map[cc].path = null;
  }

  // define pin
  pe = document.createElement("ul");
  pe.className = "pin";
  pin.node = cont.appendChild(pe);

  // event handlers
  lib.eventDebounce(svg, "mouseover", mapOver);
  lib.eventDebounce(svg, "mouseout", mapOver, 500);
  svg.addEventListener("click", mapClick, false);

  pin.node.addEventListener("mouseover", pinOver, false);
  pin.node.addEventListener("mouseout", pinOver, false);

  window.addEventListener("resize", removePin, false);

  // map hover
  function mapOver(e) {
    var cc = e.target.id;

    // out
    if (e.type.indexOf("over") < 0) {
      if (pin.active == cc && !pin.over) removePin();
      return;
    }

    // over another country
    if (cc && pin.active && pin.active != cc) {
      removePin();
    }

    // nothing to do
    if (!cc || !map[cc] || !map[cc].path || pin.active == cc) return;

    var outer = cont.getBoundingClientRect(),
      path = map[cc].path.getBoundingClientRect();

    // define pin
    pin.active = cc;
    addPinText(
      map[cc].name,
      map[cc].irlnk,
      "title" + (map[cc].irlnk ? " mi" : ""),
      true,
    );

    if (map[cc].mslnk) {
      addPinText(lang.summary, map[cc].mslnk, "subtitle ms");
    }

    if (map[cc].mytot) {
      addPinText(
        lang.livetests + ": " + "<span>" + map[cc].mytot + "</span>",
        map[cc].mylnk,
      );
    }

    if (map[cc].gmtot)
      addPinText(
        lang.gmtests + ": " + "<span>" + map[cc].gmtot + "</span>",
        map[cc].gmlnk,
      );

    if (map[cc].cmimage) {
      addPinText(lang.ctymanager, false, "subtitle");
      addImage(map[cc].cmname, map[cc].cmimage);
      addPinText(map[cc].cmname, false);
    }

    if (map[cc].gmtot && !map[cc].gmlnk) {
      if (map[cc].cmemail)
        addPinText(lang.requestaccess, "mailto:" + map[cc].cmemail, "subtitle");
      else addPinText(lang.requestaccess, lang.contactlink, "subtitle");
    } else {
      if (map[cc].cmemail)
        addPinText(lang.contact, "mailto:" + map[cc].cmemail, "subtitle");
      else addPinText(lang.contact, lang.contactlink, "subtitle");
    }

    // show pin
    pin.node.style.left = "-500px";
    pin.node.style.display = "block";
    pin.node.style.top =
      Math.max(
        0,
        Math.round(
          path.top - outer.top - pin.node.offsetHeight + path.height / 5,
        ),
      ) + "px";
    pin.node.style.left =
      Math.max(
        0,
        Math.round(
          (path.width - pin.node.offsetWidth) / 2 +
            path.left -
            outer.left -
            cont.offsetLeft,
        ),
      ) + "px";
  }

  // add text to pin
  function addPinText(txt, lnk, className, clear) {
    if (clear) {
      pin.node.style.display = "none";
      lib.empty(pin.node);
    }

    var li = pin.node.appendChild(document.createElement("li"));
    if (className) li.className = className;
    if (lnk) {
      li = li.appendChild(document.createElement("a"));
      li.href = lnk;
    } else {
      li = li.appendChild(document.createElement("span"));
    }
    li.innerHTML = txt;
  }

  // add country manager
  function addImage(alt, src) {
    var li = pin.node.appendChild(document.createElement("li"));

    li = li.appendChild(document.createElement("img"));
    li.src = src;
    li.alt = alt;
  }

  // pin hover
  function pinOver(e) {
    pin.over = e.type.indexOf("over") >= 0;
  }

  // remove pin
  function removePin() {
    pin.active = null;
    pin.over = false;
    pin.node.style.display = "none";
  }

  // map click
  function mapClick(e) {
    var cc = e.target.id,
      url;
    if (cc && map[cc] && map[cc].path) {
      url = map[cc].irlnk || (map[cc].mytot && map[cc].mylnk);
      url = url || (map[cc].gmtot && map[cc].gmlnk);
      if (url) window.location.href = url;
    }
  }
}, 300);
