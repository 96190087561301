/*
  Table control
  Permits various table-related functions

  // requires: lib.js shim-template.js
*/
/* global lib lang */

(function () {
  "use strict";

  // dependencies
  if (
    !window.addEventListener ||
    !window.history ||
    !document.body.classList ||
    !document.body.dataset
  )
    return;

  lib.queryStringParse();

  // configuration
  var timer = 0,
    currentRowId = "",
    cfg = {
      activeClass: "active",
      selectClass: "selected",
      noDataClass: "nodata",
      loader: "load",
      loadedClass: "inactive",
    },
    table = [],
    demoform = lib.id("demoformlist"),
    tabletag = lib.queryAll("table:not([class])"),
    loader = lib.id(cfg.loader),
    t,
    testlist = lib.id("testlist"),
    testlistCsv = lib.id("testlistCsv"),
    ct = lib.className("testcardtemplate")[0];

  if (tabletag.length) {
    // reset filter state
    sessionStorage.removeItem("listargs");
    // initial set of test ids
    if (lang.testids)
      sessionStorage.setItem("testids", JSON.stringify(lang.testids));
    else sessionStorage.removeItem("testids");
  }

  // handle all tables
  for (t = 0; t < tabletag.length; t++) {
    // cache elements
    table[t] = {
      tag: tabletag[t],
      form: lib.closest("form", tabletag[t]),
      thead: lib.query("thead", tabletag[t]),
      tbody: lib.query("tbody", tabletag[t]),
    };

    // not a table control
    if (!table[t].form) continue;

    table[t].sortby = lib.query('input[name="s"]', table[t].form);
    table[t].sortdir = lib.query('input[name="d"]', table[t].form);
    table[t].records = lib.className("records", table[t].form);
    table[t].recordtext = lib.queryAll("[data-records]", table[t].form);
    table[t].page = lib.className("page", table[t].form);
    table[t].pages = lib.className("pages", table[t].form);
    table[t].from = lib.className("from", table[t].form);
    table[t].start = lib.queryAll("button.icon-to-start", table[t].form);
    table[t].back = lib.queryAll("button.icon-left-dir", table[t].form);
    table[t].next = lib.queryAll("button.icon-right-dir", table[t].form);
    table[t].end = lib.queryAll("button.icon-to-end", table[t].form);

    // selected actions
    table[t].delete = lib.queryAll("button.icon-trash", table[t].form);
    table[t].select = null;
    table[t].actionActive = false;
    selectedRows(table[t]);

    // fetch row template
    var rt = lib.query("tr.rowtemplate", table[t].tbody),
      frag = document.createDocumentFragment();

    rt.removeAttribute("class");
    table[t].link = lib.query("a", rt).href || "";
    table[t].rowTemplate = frag.appendChild(rt);

    // table index
    table[t].form.dataset.index = t;

    // activate styles
    table[t].form.classList.add(cfg.activeClass);

    if (
      table[t].form &&
      table[t].thead &&
      table[t].sortby &&
      table[t].sortdir
    ) {
      // header format
      table[t].thactive = lib.query('th[class*="sort"]', table[t].thead);

      // input change and submit events
      table[t].form.addEventListener("change", formSubmit, false);
      table[t].form.addEventListener("submit", formSubmit, false);
    }

    // table click
    table[t].tag.addEventListener("click", tableClick, false);

    // tablelist hover
    if (testlist) {
      testlist.addEventListener("mouseover", rowMouseOver, false);
      testlist.addEventListener("mouseout", rowMouseOut, false);
    }

    // navigation click
    var pageinput = document.createElement("input");
    pageinput.type = "hidden";
    pageinput.name = "p";
    table[t].pageinput = table[t].form.appendChild(pageinput);

    lib
      .query("nav.tablenav", table[t].form)
      .addEventListener("click", navClick, false);
  }

  // history pop state
  window.addEventListener("popstate", historyChange);

  // find table from <table>
  function findTable(node) {
    return table[lib.closest("form", node).dataset.index];
  }

  // table clicked
  function tableClick(e) {
    e.preventDefault();
    var t = e.target;
    if (lib.closest("th", t)) headerClick(t);
    else if (e.ctrlKey || e.shiftKey) rowSelect(t, e.ctrlKey);
    else rowClick(t);
  }

  // header click
  function headerClick(target) {
    // find header
    var t = findTable(target),
      th = lib.closest("th", target),
      col = th.dataset.col;
    if (t.actionActive || !col) return;

    if (t.sortby.value != col) {
      // sort by new value
      t.sortby.value = col;
      t.sortdir.value = -1;
    } else {
      // change sort order
      t.sortdir.value = (parseInt(t.sortdir.value, 10) || 1) * -1;
    }

    // set to first page
    t.pageinput.value = 1;

    // submit update
    headerFormat(t, th);
    update(t);
  }

  // select row(s)
  function rowSelect(target, ctrl) {
    var t = findTable(target);

    // no delete action
    if (t.actionActive || !t.delete.length) return;

    var tre = lib.closest("tr", target),
      trs = ctrl || !t.select ? tre : t.select,
      nextSibling =
        (trs.offsetTop < tre.offsetTop ? "next" : "previous") +
        "ElementSibling";

    // update selected
    t.select = tre;

    // toggle selected class
    if (ctrl) trs.classList.toggle(cfg.selectClass);
    else
      do {
        if (trs !== tre) trs = trs[nextSibling];
        trs.classList.toggle(cfg.selectClass);
      } while (trs !== tre);

    // selected?
    selectedRows(t);
  }

  // enable/disable action buttons
  function selectedRows(table) {
    var selected = lib.className(cfg.selectClass, table.tbody);

    // delete action buttons
    for (var d = table.delete.length - 1; d >= 0; d--) {
      table.delete[d].parentNode.style.display = selected.length
        ? "block"
        : "none";
      table.delete[d].disabled = !selected.length;
    }

    return selected;
  }

  // row clicked
  function rowClick(target) {
    // find first link in parent TR
    var tr = lib.closest("tr", target),
      link = lib.tag("a", tr),
      t = findTable(tr);

    if (t.actionActive || !link) return;

    // get clicked row index
    var p = parseInt(t.page[0].textContent, 10), // page number
      ps = lib.id("ps", t.form), // page size
      i = 1;

    while ((tr = tr.previousElementSibling)) i++;
    if (p && ps) i += (p - 1) * parseInt(ps.value);

    // store clicked table row in sessionStorage
    // sessionStorage.setItem('listindex', i);

    // store table search in sessionStorage
    var qs = location.search.replace(/^\?/, "");
    if (!qs) qs = lib.getFormData(t.form, true);
    sessionStorage.setItem("listargs", qs);

    // redirect
    window.location = link[0].href;
  }

  // row mouseover
  function rowMouseOver(e) {
    var tr = lib.closest("tr", e.target),
      testid = tr && tr.dataset ? tr.dataset.testid : null,
      t = findTable(tr);

    if (
      t.actionActive ||
      !testid ||
      (testid === currentRowId && currentRowId != "")
    )
      return;

    timer = setTimeout(function () {
      currentRowId = testid;

      lib.ajax(
        (demoform ? "/demo/test/" : "/test/") + testid,
        function (err, url, data) {
          if (err || !data) return;

          var issueCountry =
            data.issue && lang.breach[data.issue.country]
              ? data.issue.country
              : "xx";

          var fragment = document.createDocumentFragment();
          var template = fragment.appendChild(ct.cloneNode(true));
          template.className = "testcard";
          template.id = testid;

          var ref = lib.query('strong[data-type="ref"]', template);
          var published = lib.query('time[data-type="published"]', template);
          var brand = lib.query('td[data-type="brand"]', template);
          var classification = lib.query(
            'td[data-type="classification"]',
            template,
          );
          var network = lib.query('td[data-type="network"]', template);
          var company = lib.query('ul[data-type="company"]', template);
          var status = lib.query('output[data-type="status"]', template);
          var open = lib.query('td[data-type="open"]', template);
          var acknowledged = lib.query(
            'td[data-type="acknowledged"]',
            template,
          );
          var fixed = lib.query('td[data-type="fixed"]', template);
          var closed = lib.query('td[data-type="closed"]', template);
          var closed = lib.query('td[data-type="closed"]', template);
          var breach = lib.query('section[data-type="breach"]', template);
          var media = lib.query('ol[data-type="media"]', template);

          // append HTML from data
          ref.textContent = data.ref;
          published.textContent = data.published
            ? data.published.substring(0, 10)
            : lang.unpublished;
          brand.textContent = data.brand;
          classification.textContent = lang.classification[data.classification];
          network.textContent = lang.network[data.network];
          data.company.forEach((c) => {
            company.innerHTML += "<li>" + c + "</li>";
          });
          if (data.issue && data.issue.status) {
            status.className = "status" + data.status;
            status.textContent = lang.status[data.status];
          } else {
            status.closest("fieldset").remove();
          }
          open.textContent =
            data.issue && data.issue.open
              ? data.issue.open.substring(0, 10)
              : "";
          acknowledged.textContent =
            data.issue && data.issue.acknowledged
              ? data.issue.acknowledged.substring(0, 10)
              : "";
          fixed.textContent =
            data.issue && data.issue.fixed
              ? data.issue.fixed.substring(0, 10)
              : "";
          closed.textContent =
            data.issue && data.issue.closed
              ? data.issue.closed.substring(0, 10)
              : "";
          if (data.issue && data.issue.breach) {
            data.issue.breach.forEach((b) => {
              breach.innerHTML +=
                "<p>" + lang.breach[issueCountry][b].text + "</p>";
            });
          }
          if (data.media && data.media.length && data.media.length > 0) {
            data.media.forEach((m) => {
              if (m.ext) {
                if (lang.filetype[m.ext].type == "video") {
                  media.innerHTML += `<li><figure><span class="icon-file-vid">${m.name}</span></figure></li>`;
                } else if (lang.filetype[m.ext].type == "image") {
                  media.innerHTML += `<li><figure><img src="${
                    demoform ? "https://fraudscan-dev.empello.net" : ""
                  }/asset/${m.asset}" alt="" /></figure></li>`;
                } else {
                  media.innerHTML += `<li><figure><span class="icon-file-${m.ext.substr(
                    0,
                    3,
                  )}">${m.name}</span></figure></li>`;
                }
              }

              if (!m.ext && (m.alert || m.url || m.comment)) {
                media.innerHTML += `<li><figure><span class="icon-popup">${
                  m.alert ? m.alert : ""
                }</span></figure></li>`;
              }

              media.innerHTML += "</fieldset></li>";
            });
          } else {
            media.closest("fieldset").remove();
          }

          var bounding = template.getBoundingClientRect();
          template.style.top =
            bounding.top + tr.offsetHeight + window.scrollY + "px";

          tr.parentNode.appendChild(fragment);
        },
      );
    }, 1000);
  }

  // row mouseout
  function rowMouseOut(e) {
    var tr = lib.closest("tr", e.target),
      testid = tr && tr.dataset ? tr.dataset.testid : null,
      t = findTable(tr);

    if (t.actionActive || !testid) return;

    if (timer) {
      clearTimeout(timer);
    }

    if (document.getElementById(testid)) {
      setTimeout(function () {
        tr.parentNode.removeChild(document.getElementById(testid));
      }, 300);
    }
  }

  // navigation button click
  function navClick(e) {
    var button = e.target;
    if (button.nodeName != "BUTTON") return;

    e.preventDefault();

    var t = findTable(button);
    if (t.actionActive) return;

    if (button.value === "delete") {
      deleteSelected(t);
    } else {
      // navigation
      t.pageinput.value = button.value;
      update(t);
    }
  }

  // delete selected
  function deleteSelected(table) {
    selectedLinkAction(table, lang.delete, function (item) {
      table.actionActive = true;
      loading(true);

      var i,
        todo = item.length,
        fail = [];

      for (i = 0; i < todo; i++) {
        lib.ajax({ action: item[i], method: "DELETE" }, deleteComplete);
      }

      // deletion completed?
      function deleteComplete(err, retUrl) {
        if (err) fail.push(retUrl);

        todo--;
        if (todo > 0) return;

        // deactivate loading
        table.actionActive = false;
        loading();

        // refresh
        update(table);

        // deletion errors?
        if (!fail.length) return;

        var modal = {
          header: lang.delete,
          message:
            lang.delete +
            " " +
            lang.error +
            ": " +
            fail.length +
            " " +
            (fail.length === 1 ? lang.one : lang.many),
          buttons: {},
        };
        modal.buttons[lang.OK] = null;
        lib.modal(modal);
      }
    });
  }

  // get links of selected items
  function selectedLinkAction(table, type, callback) {
    var item = [],
      selected = selectedRows(table),
      s,
      a;

    for (s = 0; s < selected.length; s++) {
      a = lib.tag("a", selected[s]);
      if (a.length && a[0].href) item.push(a[0].href);
    }

    if (item.length) {
      var modal = {
        header: type,
        message:
          type +
          " " +
          item.length +
          " " +
          (item.length === 1 ? lang.one : lang.many) +
          "?",
        buttons: {},
        callback: function (confirm) {
          if (confirm) callback(item);
        },
      };
      modal.buttons[type] = 1;
      modal.buttons[lang.cancel] = "";
      lib.modal(modal);
    }

    return item;
  }

  // form submitted
  function formSubmit(e) {
    if (e.type == "submit") e.preventDefault();
    else {
      // new search, show page 1
      var t = findTable(e.currentTarget);
      if (t.actionActive) return;

      t.pageinput.value = "";

      // wait for form updates to complete
      setTimeout(function () {
        update(t);
      }, 10);
    }
  }

  // history change
  function historyChange(h) {
    // single state change
    update(
      table[h && h.state && h.state.tableindex ? h.state.tableindex : 0],
      String(document.location),
    );
  }

  // update table data
  function update(t, useUrl) {
    let testIds = [];
    if (sessionStorage.getItem("testids")) sessionStorage.removeItem("testids");

    loading(true);

    lib.ajax(useUrl || t.form, function (err, url, obj) {
      if (!err && obj.info && obj.data) {
        if (!useUrl) {
          // new fetch - push history
          history.pushState({ tableindex: t.form.dataset.index }, "list", url);
        }

        if (obj.data.length > 0) {
          for (let t of obj.data) {
            testIds.push(t._id);
          }
        }

        // update form
        var param = lib.queryStringParse();
        param.ps = obj.info.pagesize;
        lib.updateForm(t.form, param);

        if (testlistCsv) {
          setTimeout(() => {
            testlistCsv.href =
              "/test/list/" +
              (location.search ? location.search : "?published=1") +
              "&format=csv";
          }, 50);
        }

        // update table header
        headerFormat(t);

        // update navigation
        navigationInfo(t, obj.info);

        // update table rows
        if (obj.data.length === 0) {
          t.form.classList.add(cfg.noDataClass);
          if (sessionStorage.getItem("testids"))
            sessionStorage.removeItem("testids");
        } else {
          rowUpdate(t, obj.data);
          t.form.classList.remove(cfg.noDataClass);
          sessionStorage.setItem("testids", JSON.stringify(testIds));
        }

        // unselect
        selectedRows(t);
        t.select = null;
      } else {
        // timeout message
        if (lang && lang.OK && lang.timeout) {
          var modal = {
            header: lang.timeout,
            message: lang.timeoutmsg || "",
            buttons: {},
          };
          modal.buttons[lang.OK] = 1;
          lib.modal(modal);
        }
      }

      loading();
    });
  }

  // show or hide loader
  function loading(on) {
    if (loader && loader.classList) {
      loader.classList[on ? "remove" : "add"](cfg.loadedClass);
    }
  }

  // assign the correct header sorting class
  function headerFormat(t, th) {
    if (t.thactive) {
      t.thactive.classList.remove("sort1");
      t.thactive.classList.remove("sort-1");
    }

    t.thactive =
      th || lib.query('th[data-col^="' + t.sortby.value + '"]', t.thead);
    if (t.thactive) t.thactive.classList.add("sort" + t.sortdir.value);
  }

  // update navigation information
  function navigationInfo(t, info) {
    // update text values
    valueUpdate(t.records, info.records);
    valueUpdate(t.page, info.page);
    valueUpdate(t.pages, info.pages);
    valueUpdate(t.from, info.from);

    // update buttons
    buttonUpdate(t.start, 1, info.page > 1);
    buttonUpdate(t.back, Math.max(1, info.page - 1), info.page > 1);
    buttonUpdate(
      t.next,
      Math.min(info.pages, info.page + 1),
      info.page < info.pages,
    );
    buttonUpdate(t.end, info.pages, info.page < info.pages);

    // udpate record text
    var i = info.records == 1 ? 0 : 1,
      rt,
      opt;
    for (rt = 0; rt < t.recordtext.length; rt++) {
      opt = t.recordtext[rt].dataset.records.split(",");
      if (opt[i]) t.recordtext[rt].textContent = opt[i];
    }

    function valueUpdate(node, value) {
      if (!node || node.length === 0) return;
      for (var n = 0; n < node.length; n++) node[n].textContent = value;
    }

    function buttonUpdate(node, p, enable) {
      if (!node || node.length === 0) return;
      for (var n = 0; n < node.length; n++) {
        node[n].value = p;
        node[n].disabled = !enable;
      }
    }
  }

  // update table
  function rowUpdate(t, data) {
    // create new tbody fragment
    var tbody = document.createElement("tbody"),
      row,
      col,
      r,
      c,
      vName,
      vType,
      v,
      value;

    // all data rows
    for (r = 0; r < data.length; r++) {
      row = tbody.appendChild(t.rowTemplate.cloneNode(true));
      row.dataset.testid = data[r]._id;
      col = row.querySelectorAll("[data-col]");

      // all columns
      for (c = 0; c < col.length; c++) {
        vName = col[c].dataset.col.split(",");
        vType = (col[c].dataset.type || "").split(",");

        // all column values
        for (v = 0; v < vName.length; v++) {
          // column value
          value = data[r][vName[v]];

          if (value === false || typeof value == "undefined") value = "";
          else if (value === true) value = vName[v];
          else if (typeof value == "number" && vType[v] == "class")
            value = vName[v] + value;

          switch (vType[v]) {
            case "class":
              // add class
              if (value) col[c].classList.add(value);
              break;

            case "href":
              // set link href
              col[c].href = t.link + value;
              break;

            case "title":
              // add title
              if (value) col[c].title = value;
              break;

            default:
              // set text of final child element
              var tNode = col[c];
              while (tNode.firstElementChild) tNode = tNode.firstElementChild;
              if (demoform && vName[v] === "network")
                tNode.textContent = lang.network[value];
              else if (demoform && vName[v] === "adflow")
                tNode.textContent = lang.adflow[value];
              else if (vName[v] === "month")
                tNode.textContent = lang.month[value];
              else tNode.textContent = value;
          }
        }
      }
    }

    // replace table body
    t.tbody.parentNode.replaceChild(tbody, t.tbody);
    t.tbody = lib.query("tbody", t.tag);
  }
})();
