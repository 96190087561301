/*
  Generic form event handler
  ensures URLs are valid and opens link in a new window

  // requires: lib.js
*/
/* global assure lib lang */

assure.form = (function () {
  "use strict";

  // dependencies
  if (!window.addEventListener) return;

  var start = false,
    submitting = false,
    cfg = {
      valueTag: ["input", "output", "p"],
    },
    fileForm = !!lib.query('form[enctype="multipart/form-data"]'),
    navSubmit = lib.query("nav.submit"),
    login = lib.id("loginsubmit"),
    navUsed;

  // set field focus
  setFocus();

  // initialise toggles
  lib.each(lib.queryAll("[data-switch-set]"), toggleHandler);
  lib.each(lib.queryAll("[data-switch-unset]"), toggleHandler);
  lib.each(lib.queryAll("[data-switch]"), toggleHandler);

  // initialise selects
  lib.each(lib.queryAll("[data-select]"), selectHandler);

  // handle generic change and click events
  document.body.addEventListener("change", changeEvent, false);
  document.body.addEventListener("click", clickEvent, false);

  // generic form submit
  if (!fileForm && navSubmit) {
    navSubmit.addEventListener(
      "click",
      function (e) {
        navUsed = lib.closest("button", e.target);
      },
      false,
    );
  }

  // prevent unsaved data
  document.body.addEventListener("submit", formSubmit, false);
  window.onbeforeunload = function () {
    var alert = null,
      form = lib.tag("form"),
      f;

    if (!submitting && assure.formChanged) {
      // check whether forms requiring unload check have changed
      for (f = form.length - 1; !alert && f >= 0; f--) {
        if (
          form[f].dataset.unloadCheck == "true" &&
          assure.formChanged.check(form[f])
        )
          alert = true;
      }
    }

    if (alert) return lang && lang.unsaved ? lang.unsaved : "";
  };

  // set initial field focus
  function setFocus() {
    var qs = lib.queryStringParse(),
      iField;

    if (qs.focus) {
      iField = lib.id(qs.focus);
      if (iField && iField.focus) iField.focus();
    }
  }

  // change events
  function changeEvent(e) {
    var t = e.target,
      node = t.nodeName,
      type = t.type;

    if (node === "INPUT" && type === "url") formatLink(e);

    if (t.dataset.switch || t.dataset.switchSet || t.dataset.switchUnset)
      toggleHandler(t);

    if (t.dataset.select) selectHandler(t);
  }

  // click events
  function clickEvent(e) {
    var t = e.target;
    if (
      t.nodeName === "A" &&
      (t.hash === "#url" || t.classList.contains("url"))
    )
      visitLink(e);

    // allow radio buttons to be unset when true (data-unset attribute)
    if (t.nodeName === "LABEL") {
      var c = t.control || (t.htmlFor && lib.id(t.htmlFor));
      if (
        c &&
        c.nodeName === "INPUT" &&
        c.type === "radio" &&
        c.dataset.unset &&
        c.checked
      ) {
        c.checked = false;
        e.preventDefault();
        if (c.dataset.switch || c.dataset.switchSet || c.dataset.switchUnset)
          toggleHandler(c);
      }
    }
  }

  // format link (input type="url")
  function formatLink(e) {
    var val = e.target.value,
      uri = val.trim();
    if (uri && uri.toLowerCase().indexOf("http") !== 0) uri = "http://" + uri;
    if (uri != val) e.target.value = uri;
  }

  // visit link (a href="#url")
  function visitLink(e) {
    e.preventDefault();

    // find items within parent
    var t = e.target,
      uri = t.classList.contains("url") ? t.href : "",
      ve,
      inp,
      v = 0;
    while (!uri && v < cfg.valueTag.length) {
      ve = lib.tag(cfg.valueTag[v], e.target.parentNode);
      if (ve.length > 0) {
        inp = ve[0];
        uri = inp.value || inp.textContent || "";
      }
      v++;
    }

    if (uri) {
      // open link (strip querystring)
      window.open(uri.replace(/\?.*/, "")).focus();
    } else if (inp && inp.focus) {
      // set focus to empty field
      inp.focus();
    }
  }

  // handle switch links
  function toggleHandler(c) {
    var switchName = c.dataset.switch,
      switchSet = c.dataset.switchSet || c.dataset.switchUnset,
      checked;

    switch (c.nodeName.toLowerCase()) {
      case "input":
        var type = c.type.toLowerCase();
        if (type == "checkbox" || type == "radio") checked = c.checked;
        else checked = !!c.value;
        break;

      case "textarea":
        checked = !!c.value;
        break;

      case "select":
        checked = !!c.options[c.selectedIndex].value;
        break;

      default:
        checked = !!c.textContent;
        break;
    }

    // toggle switch activated
    if (switchName) {
      // show
      lib.each(
        lib.queryAll('[data-switch-show="' + switchName + '"]'),
        function (e) {
          showHide(e, checked);
        },
      );

      // hide
      lib.each(
        lib.queryAll('[data-switch-hide="' + switchName + '"]'),
        function (e) {
          showHide(e, !checked);
        },
      );

      if (start) {
        // set
        lib.each(
          lib.queryAll('input[data-switch-set="' + switchName + '"]'),
          function (e) {
            e.checked = checked;
          },
        );

        // unset
        lib.each(
          lib.queryAll('input[data-switch-unset="' + switchName + '"]'),
          function (e) {
            e.checked = !checked;
          },
        );
      }
    }

    // switch set
    if (start && switchSet && !checked) {
      lib.each(
        lib.queryAll('input[data-switch="' + switchSet + '"]'),
        function (e) {
          e.checked = false;
        },
      );
    }
  }

  // handle select changes
  function selectHandler(s) {
    var sName = "data-select-" + s.dataset.select,
      sChosen = Array.prototype.slice.call(
        lib.queryAll("[" + sName + '="' + s.value + '"]'),
      );

    if (!sChosen.length)
      sChosen = Array.prototype.slice.call(lib.queryAll("[" + sName + '=""]'));

    lib.each(lib.queryAll("[" + sName + "]"), function (e) {
      showHide(e, sChosen.indexOf(e) >= 0);
    });
  }

  // show or hide an element
  function showHide(e, on) {
    if (!e.dataset.blockstyle)
      e.dataset.blockstyle = lib.appliedStyle(e, "display") || "block";
    if (on) {
      // show element
      e.disabled = false;
      e.style.display = e.dataset.blockstyle;
    } else {
      // hide element and disable inputs
      e.style.display = "none";
      if (!e.dataset.nodisable) e.disabled = true;
    }
  }

  // form submission events
  function formSubmit(e) {
    submitting = true;

    // disable login button to prevent multiple requests
    if (login) login.disabled = true;

    if (navUsed && navUsed.name == "doaction") {
      // delete action - confirm
      if (navUsed.value == "delete") {
        e.preventDefault();
        cancelSubmit();

        var modal = {
          header: lang.delete,
          message: lang.sure,
          buttons: {},
          callback: function (start) {
            if (!start) return;

            var form = e.target,
              action = document.createElement("input");

            action.type = "hidden";
            action.name = "doaction";
            action.value = "delete";
            form.appendChild(action);
            submitting = true;
            form.submit();
          },
        };

        modal.buttons[lang.OK] = 1;
        modal.buttons[lang.cancel] = "";
        lib.modal(modal);
        return;
      }
    }
  }

  // cancel submit action (delayed to allow other actions to complete)
  function cancelSubmit() {
    setTimeout(function () {
      submitting = false;
    }, 10);
  }

  start = true;

  // allow submit to be cancelled
  return {
    cancelSubmit: cancelSubmit,
  };
})();
