/*
  download a file

  // requires: lib.js
*/
/* global lib */

(function () {
  "use strict";

  var cfg = {
      url: "/downloadfile/",
    },
    file,
    fileEnc,
    buttons = lib.queryAll(".downloadfile");

  // no suppport
  if (!buttons || !window.addEventListener || !document.body.classList) return;

  buttons.forEach((item) => {
    item.addEventListener("click", (e) => {
      file = e.target.textContent;

      fileEnc = encodeURIComponent(file);

      window.open(cfg.url + fileEnc);
    });
  });
})();
