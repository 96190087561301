/*
  Previews images displayed on test form

  // requires: lib.js
*/
/* global lib */

(function () {
  "use strict";

  // configuration
  var mp = {
    media: lib.id("media"),
    snap: 50,
  };

  if (!mp.media) mp.media = lib.query(".media");

  // no support
  if (!mp.media || !window.addEventListener || !document.body.classList) return;

  // image click event
  mp.media.addEventListener(
    "click",
    function (e) {
      var t = e.target;
      if (t.nodeName !== "IMG" && t.nodeName !== "H3") return;

      var item = lib.closest("li", t);
      if (item.parentNode === mp.media) {
        e.preventDefault();
        e.stopPropagation();
        previewItem(item);
      }
    },
    false,
  );

  // preview item
  var mPreview, mInner, mImage, mText, mComment, mCurrent, zoom;
  function previewItem(item) {
    if (!mPreview) {
      // create preview overlay and elements
      var outer = document.createElement("div"),
        back,
        next,
        close;

      // outer element
      outer.id = "preview";
      outer.tabIndex = -1;

      // image and container
      mInner = outer.appendChild(document.createElement("div"));
      mImage = mInner.appendChild(document.createElement("img"));
      mText = mInner.appendChild(document.createElement("article"));
      mComment = mInner.appendChild(document.createElement("p"));

      // back/next/close buttons
      back = outer.appendChild(document.createElement("a"));
      back.href = "#back";
      back.className = "back";

      next = outer.appendChild(document.createElement("a"));
      next.href = "#next";
      next.className = "next";

      close = outer.appendChild(document.createElement("a"));
      close.href = "#close";
      close.className = "close";

      // append to body
      mPreview = document.body.appendChild(outer);

      // element clicked or ESC key
      mPreview.addEventListener("click", previewEvent, false);
      mPreview.addEventListener("keydown", previewEvent, false);
      mImage.addEventListener("mouseover", zoomStart, false);
      mImage.addEventListener("touchstart", zoomStart, false);
      mInner.addEventListener("mousemove", zoomImage, false);
      mInner.addEventListener("touchmove", zoomImage, false);
      mImage.addEventListener("mouseout", zoomEnd, false);
      mImage.addEventListener("touchend", zoomEnd, false);
    }

    mCurrent = item;
    var cont,
      txt,
      img = lib.query("img", item);

    if (img) {
      // image item
      mImage.src = img.src;
      zoomEnd();
      mImage.style.display = "block";
      mText.style.display = "none";
    } else {
      // text item
      mImage.style.display = "none";
      cont = lib.query("textarea", item) || lib.query("p.alert", item);
      txt = cont && (cont.value || cont.textContent).trim();
      mText.textContent = txt;
      mText.style.display = txt ? "block" : "none";
    }

    // append comment
    cont = lib.tag("textarea", item);

    if (cont.length) {
      // textarea item
      txt = cont[cont.length - 1].value;
    } else {
      // paragraphs
      cont = lib.tag("p", item);
      txt = "";
      lib.each(cont, function (i) {
        txt += i.classList.contains("alert") ? "" : i.textContent.trim() + " ";
      });
    }

    txt = txt.trim();
    mComment.textContent = txt;
    mComment.style.display = txt ? "block" : "none";

    // show previewer
    if (!mPreview.classList.contains("active"))
      mPreview.classList.add("active");
    mPreview.focus();
  }

  // preview event occurred
  function previewEvent(e) {
    e.preventDefault();

    var type = e.type,
      t = e.target,
      code = type == "keydown" ? e.keyCode : 0;

    if (
      (type == "click" &&
        t.nodeName == "A" &&
        !t.classList.contains("close")) ||
      (code >= 32 && code <= 34) ||
      (code >= 37 && code <= 40) ||
      code == 188 ||
      code == 190
    ) {
      showNext(
        t.className == "back" ||
          code == 33 ||
          code == 37 ||
          code == 38 ||
          code == 188
          ? -1
          : 1,
      );
    } else if (type == "click" && t.nodeName == "IMG") {
      window.open(t.src).focus();
    } else if (type == "click" && lib.closest("article", t)) {
      return;
    } else if (type == "click" || code == 27) {
      mPreview.classList.remove("active");
      lib.query("a", mCurrent).focus();
      mCurrent = null;
    }
  }

  // show next image in sequence
  function showNext(dir) {
    if (!mCurrent) return;

    var item =
      dir < 0 ? mCurrent.previousElementSibling : mCurrent.nextElementSibling;
    if (!item) {
      item = dir < 0 ? mp.media.lastElementChild : mp.media.firstElementChild;
    }

    if (item.nodeName == "LI" && !lib.query("span", item)) previewItem(item);
    else {
      mCurrent = item;
      showNext(dir);
    }
  }

  // start zoom
  function zoomStart(e) {
    // can image be zoomed?
    zoom = {
      imgW: mImage.naturalWidth || 0,
      imgH: mImage.naturalHeight || 0,
      conW: mInner.offsetWidth || 0,
      conH: mInner.offsetHeight || 0,
      offX: mInner.offsetLeft || 0,
      offY: mInner.offsetTop || 0,
    };

    zoom.movX = zoom.imgW > zoom.conW;
    zoom.movY = zoom.imgH > zoom.conH;

    if (zoom.movX || zoom.movY) {
      // start zoom
      mImage.classList.add("zoom");

      if (zoom.movX) {
        mImage.style.left = "auto";
        mImage.style.right = "auto";
      }
      if (zoom.movY) {
        mImage.style.top = "auto";
        mImage.style.bottom = "auto";
      }

      zoomImage(e);
    } else {
      // no need to zoom
      zoom = false;
    }
  }

  // zoom image
  function zoomImage(e) {
    if (!zoom) return;

    var posE = e.touches && e.touches.length ? e.touches[0] : e,
      curX = posE.clientX - zoom.offX,
      curY = posE.clientY - zoom.offY;

    curX =
      curX - mp.snap < 0 ? 0 : curX + mp.snap > zoom.conW ? zoom.conW : curX;
    curY =
      curY - mp.snap < 0 ? 0 : curY + mp.snap > zoom.conH ? zoom.conH : curY;

    mImage.style.left =
      (zoom.movX
        ? Math.round(
            (1 - (zoom.conW - curX) / zoom.conW) * (zoom.conW - zoom.imgW),
          )
        : 0) + "px";
    mImage.style.top =
      (zoom.movY
        ? Math.round(
            (1 - (zoom.conH - curY) / zoom.conH) * (zoom.conH - zoom.imgH),
          )
        : 0) + "px";
  }

  // end zoom
  function zoomEnd() {
    mImage.classList.remove("zoom");
    mImage.style.left = 0;
    mImage.style.right = 0;
    mImage.style.top = 0;
    mImage.style.bottom = 0;
    zoom = false;
  }
})();
