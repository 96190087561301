/*
  Check if form has changed

  // requires: config.js lib.js form.js file-drop.js test-company.js
*/
/* global assure lib */

assure.formChanged = (function () {
  "use strict";

  var check;

  // dependencies
  if (!window.addEventListener) {
    // default function - no checking
    check = function () {
      return false;
    };
    return;
  }

  // parse all forms
  var formStore = [],
    form = lib.tag("form"),
    f,
    i,
    e,
    nn,
    fsi;
  for (f = form.length - 1; f >= 0; f--) {
    // formStore index
    form[f].fcIndex = fsi = formStore.length;
    formStore[fsi] = { value: {} };

    // form element count
    formStore[fsi].count = form[f].elements.length;

    // store hidden element initial value
    for (i = 0; i < formStore[fsi].count; i++) {
      e = form[f].elements[i];
      if (e.name) {
        formStore[fsi].value[e.name] = formStore[fsi].value[e.name] || [];
        nn = e.nodeName.toLowerCase();

        if (nn == "select") {
          // store select value - select multiple is not supported
          formStore[fsi].value[e.name].push(e.options[e.selectedIndex].value);
        } else if (nn == "input" && e.type.toLowerCase() == "hidden") {
          // store hidden value
          formStore[fsi].value[e.name].push(e.value);
        }
      }
    }
  }

  // look for form changes
  check = function (form) {
    var fsi =
      typeof form.fcIndex == "undefined" ? -1 : parseInt(form.fcIndex, 10);

    if (
      !form ||
      !form.nodeName ||
      form.nodeName.toLowerCase() != "form" ||
      fsi < 0 ||
      fsi >= formStore.length
    )
      return null;

    // get stored value
    var store = formStore[fsi],
      el = form.elements.length,
      changed = el != store.count,
      hidden = {},
      i,
      e;

    for (i = 0; !changed && i < el; i++) {
      e = form.elements[i];
      if (e.name && !e.disabled) {
        // element seen before?
        changed = typeof store.value[e.name] == "undefined";

        // check default value
        switch (e.nodeName.toLowerCase()) {
          // select boxes
          case "select":
            changed =
              changed ||
              !store.value[e.name].length ||
              e.options[e.selectedIndex].value != store.value[e.name][0];
            break;

          // input/textarea
          case "textarea":
          case "input":
            switch (e.type.toLowerCase()) {
              case "checkbox":
              case "radio":
                // checkbox/radio
                changed = e.checked !== e.defaultChecked;
                break;
              case "hidden":
                // hidden value
                if (typeof hidden[e.name] == "undefined") hidden[e.name] = 0;
                else hidden[e.name]++;
                changed = e.value !== store.value[e.name][hidden[e.name]];
                break;
              default:
                // standard values
                changed = e.value !== e.defaultValue;
                break;
            }
            break;
        }
      }

      // DEBUG
      // if (changed) console.log(e.name + ' changed');
    }

    return changed;
  };

  // public methods
  return {
    check: check,
  };
})();
