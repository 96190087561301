/*
  Test page company control
  Allows companies to be added and removed from a test

  // requires: lib.js shim-template.js
*/
/* global cardData lang lib */

(function () {
  "use strict";

  var cfg = {
      assetUrl: "/asset/",
      maxLoad: 6,
      nextImageDelay: 300,
      loadTimeout: 30000,
      col2: 800,
      row2: 800,
      classNoList: "none",
      classMerchant: "merchant",
      classReveal: "reveal",
      classNew: "highlight",
    },
    card = {
      template: lib.id("cardtemplate"),
    },
    demoform = lib.id("demoadscanner");

  if (
    !window.cardData ||
    !window.lang ||
    !card.template ||
    !document.body.classList
  )
    return;

  // initialise
  var showStart = parseInt(window.location.hash.replace(/\D/g, ""), 10) || 0,
    listEnd = true,
    loading = 0,
    u;

  card.container = card.template.parentNode;
  card.active = null;

  card.outer = card.template.content.querySelector("li");
  if (!showStart) card.outer.classList.add(cfg.classReveal);

  card.figure = card.template.content.querySelector("figure");
  card.new = card.template.content.querySelector(".new");
  card.tests = card.template.content.querySelector(".icon-eye");
  card.time = card.template.content.querySelector(".icon-clock");
  card.classification = card.template.content.querySelector(".classification");
  card.adflow = card.template.content.querySelector(".adflow");
  card.paytype = card.template.content.querySelector(".paytype");
  card.connection = card.template.content.querySelector(".connection");
  card.brand = card.template.content.querySelector(".brand");
  card.company = card.template.content.querySelector(".company");
  card.link = card.template.content.querySelectorAll(".link a");
  card.linkUrl = [];
  for (u = 0; u < card.link.length; u++) card.linkUrl[u] = card.link[u].href;

  // this is a video gallery
  var video = lang.video;

  // history change
  window.addEventListener(
    "unload",
    function () {
      card.active = card.active || "c" + (card.container.childElementCount - 1);
      history.replaceState(null, "adscanner", "#" + card.active);
    },
    false,
  );

  // active card click event
  card.container.addEventListener("click", function (e) {
    var t = e.target;
    if (t.nodeName !== "A") return;
    while (!t.id && t.parentNode) t = t.parentNode;
    if (t.id && t.id.match(/^c\d+$/)) card.active = t.id;
  });

  // scrolled to page end event
  lib.scrollToEnd(loadCards);

  // load further cards
  function loadCards(atEnd) {
    if (typeof atEnd !== "undefined") listEnd = atEnd;

    // all cards displayed or maximum load reached
    if (!cardData.length || loading >= cfg.maxLoad) {
      if (showStart) setTimeout(loadCards, cfg.nextImageDelay);
      return;
    }

    // load next card
    var newCard = cardData.shift();
    if (!newCard.asset) return;
    loading++;

    var media = video ? document.createElement("video") : new Image(),
      iTimeout = setTimeout(function () {
        media = null;
        iTimeout = null;
        loading--;
      }, cfg.loadTimeout);

    media.src =
      (demoform ? "https://fraudscan-dev.empello.net" : "") +
      cfg.assetUrl +
      newCard.asset;
    if (video) {
      media.controls = true;
      media.style.width = "100%";
      media.style.height = "60vh";
    }
    if (!video) media.alt = lang.mediatype || "";

    if (showStart || (!video && media.complete)) addCard();
    else if (!video) media.onload = addCard;
    else
      media.addEventListener(
        "loadeddata",
        function () {
          addCard();
        },
        false,
      );

    // load next card if list end is visible
    if (listEnd || showStart)
      setTimeout(loadCards, showStart ? 10 : cfg.nextImageDelay);

    // image loaded - add new card
    function addCard() {
      if (iTimeout) {
        clearTimeout(iTimeout);
        loading--;
      }

      // populate new card
      card.outer.id = "c" + card.container.childElementCount;

      if (media.naturalWidth > cfg.col2) card.outer.classList.add("col2");
      else card.outer.classList.remove("col2");

      if (media.naturalHeight > cfg.row2) card.outer.classList.add("row2");
      else card.outer.classList.remove("row2");

      lib.empty(card.figure);
      if (video) {
        card.figure.style["max-height"] = "65vh";
        card.outer.style.padding = "0 0 0.5em";
      }
      card.figure.appendChild(media);
      if (newCard.new) {
        card.new.textContent = lang["newbanner"].toUpperCase();
        card.new.classList.add(cfg.classNew);
      } else {
        card.new.textContent = "";
        card.new.classList.remove(cfg.classNew);
      }
      card.tests.textContent =
        newCard.tests + " " + lang["test" + (newCard.tests === 1 ? "" : "s")];
      card.time.textContent = newCard.recent;
      card.time.setAttribute("datetime", newCard.recent);
      appendList(card.classification, newCard.classification);
      appendList(card.adflow, newCard.adflow);
      appendList(card.paytype, newCard.paytype);
      appendList(card.connection, newCard.connection);
      appendList(card.brand, newCard.brand);
      appendList(card.company, newCard.company, isMerchant);

      // links
      for (u = 0; u < card.link.length; u++) {
        card.link[u].href = card.linkUrl[u] + newCard.asset;
      }

      card.container.appendChild(
        document.importNode(card.template.content, true),
      );

      // completed initial cards?
      if (showStart) {
        showStart--;
        if (showStart === 0) {
          card.outer.classList.add(cfg.classReveal);
          showStart = null;
          window.scrollTo(0, card.container.clientHeight);
        }
      }
    }

    // is company a merchant?
    function isMerchant(node, comp) {
      if (comp.lastIndexOf(")") !== comp.length - 1)
        node.classList.add(cfg.classMerchant);
      return node;
    }

    // append list to node
    function appendList(list, item, meta) {
      if (!list) return;

      lib.empty(list);

      if (!item || !item.length) {
        list.classList.add(cfg.classNoList);
        return;
      }

      list.classList.remove(cfg.classNoList);

      item.forEach(function (i) {
        var li = document.createElement("li");
        if (meta) li = meta(li, i);
        li.textContent = i;
        list.appendChild(li);
      });
    }
  }
})();
