/*
  Textarea extender
  changes the height of <textarea> elements according to content

  // requires: lib.js
*/
/* global assure lib */

assure.textarea = (function () {
  "use strict";

  var cfg = {
    maxHeight: 200,
  };

  init();

  function init() {
    var textarea = lib.queryAll("textarea:not([data-control])");
    for (var t = 0; t < textarea.length; t++) {
      textarea[t].dataset.control = 1;
      textarea[t].addEventListener("focus", contentChange, false);
      textarea[t].addEventListener("keyup", contentChange, false);
      textarea[t].addEventListener("blur", trimContent, false);
      contentChange(textarea[t]);
    }
  }

  // trim textarea content
  function trimContent(t) {
    t = t.target;
    t.value = t.value.trim().replace(/\n{2,}/g, "\n\n");
    contentChange(t);
  }

  // textarea content changed
  function contentChange(t) {
    t = t.target || t;
    var p = t.parentNode;

    requestAnimationFrame(function () {
      p.style.height = p.offsetHeight + "px";
      t.style.height = t.target ? "auto" : "0px";
      var m = t.disabled || t.readOnly ? 1000 : cfg.maxHeight,
        h = Math.min(t.scrollHeight, m);
      t.style.height = h + "px";
      t.style.overflow = t.scrollHeight > h ? "auto" : "hidden";
      p.style.height = "auto";
    });
  }

  // allow other textareas to be initialised
  return {
    init: init,
    contentChange: contentChange,
  };
})();
