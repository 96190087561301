(function () {
  "use strict";

  var trialCheck = lib.id("servicetrial");
  var trialDays = lib.id("servicetrialdays");
  var brand = lib.id("brand");
  var canaddnew = lib.id("canaddnew");

  // no support or relevant field
  if (!brand || !trialCheck || !window.addEventListener) return;

  brand.addEventListener("focusout", changeBrand, false);

  // service_trial checkbox event
  trialCheck.addEventListener("change", updateTrialDays, false);

  if (trialDays.value) trialDays.parentNode.style.visibility = "visible";

  function changeBrand(e) {
    let t = e.target;
    let val = t.value;

    if (!val || canaddnew?.value === "true") return;

    // ajax call
    lib.ajax(
      "/autocomplete/brand/" + encodeURIComponent(val),
      function (err, url, bdata) {
        if (err && !bdata) {
          // only authorised users can add a new brand
          brand.value = "";
          var modal = {
            header: lang.brandnew,
            message: lang.brandnewnotallowed,
            buttons: {},
          };
          modal.buttons[lang.OK] = 1;
          lib.modal(modal);
          return;
        }
      },
    );
  }

  function updateTrialDays(e) {
    let t = e.target;
    if (t.checked) {
      trialDays.parentNode.style.visibility = "visible";
    } else {
      trialDays.parentNode.style.visibility = "hidden";
      trialDays.value = "";
    }
  }
})();
