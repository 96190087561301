/*
  Utility library

  // requires: config.js
*/

var lib = (function () {
  "use strict";

  // each
  function each(obj, fn) {
    if (obj.length || obj.length === 0)
      for (
        var i = 0, ol = obj.length, v = obj[0];
        i < ol && fn(v, i) !== false;
        v = obj[++i]
      );
    else for (var p in obj) if (fn(obj[p], p) === false) break;
  }

  // returns true if all passed values are similar (ignore case, spacing, etc)
  var reNorm = /\W/g;
  function similar() {
    var same = true,
      i = 0,
      a,
      match;
    while (same && i < arguments.length) {
      a = arguments[i].replace(reNorm, "").toLowerCase();
      if (i === 0) match = a;
      else same = a == match;
      i++;
    }
    return same;
  }

  // normalise a string - no non-word characters, lowercase
  function normalise(str) {
    return str.replace(reNorm, "").toLowerCase();
  }

  // pad a string
  function pad(str, len, chr) {
    str = String(str);
    len = len || 2;
    chr = chr || "0";
    while (str.length < len) str = chr + str;
    return str;
  }

  // to integer
  function int(num) {
    return parseInt(num, 10) || 0;
  }

  // getElementsById
  function id(idName) {
    return document.getElementById(idName);
  }

  // getElementsByTagName
  function tag(tagName, doc) {
    doc = doc || document;
    return doc.getElementsByTagName(tagName);
  }

  // getElementsByClassName
  function className(cn, doc) {
    doc = doc || document;
    return doc.getElementsByClassName(cn);
  }

  // querySelector
  function query(sel, doc) {
    doc = doc || document;
    return doc.querySelector(sel);
  }

  // querySelectorAll
  function queryAll(sel, doc) {
    doc = doc || document;
    return doc.querySelectorAll(sel);
  }

  // closest
  function closest(type, node) {
    type = type.toUpperCase();
    var pNode = null;
    while (!pNode && node.parentNode) {
      if (node.nodeName == type) pNode = node;
      node = node.parentNode;
    }
    return pNode;
  }

  // remove all child nodes
  function empty(node) {
    while (node.lastChild) node.removeChild(node.lastChild);
  }

  // update a select drop-down
  function setSelect(select, value) {
    var s = 0,
      si = -1;

    while (si < 0 && s < select.options.length) {
      if (select.options[s].value == value) si = s;
      s++;
    }

    if (si >= 0) select.selectedIndex = si;
    return si;
  }

  // get computed style
  function appliedStyle(node, prop) {
    return window.getComputedStyle(node, null).getPropertyValue(prop);
  }

  // top position
  function posTop(node) {
    var top = 0;
    do {
      top += node.offsetTop;
    } while ((node = node.offsetParent));
    return top;
  }

  // left position
  function posLeft(node) {
    var left = 0;
    do {
      left += node.offsetLeft;
    } while ((node = node.offsetParent));
    return left;
  }

  // parses the URL querystring
  function queryStringParse(str) {
    var val = {},
      qs = (str || window.location.search.slice(1)).split("&"),
      q,
      v;

    // parse individual values
    for (q = 0; q < qs.length; q++) {
      v = qs[q].split("=");
      if (v.length == 2) {
        val[v[0]] = decodeURI(v[1]);
      }
    }

    return val;
  }

  // create object from form data (set forQS true to create a querystring)
  function getFormData(form, forQS) {
    var arg = forQS ? [] : {},
      e,
      fe,
      val;
    for (e = 0; e < form.elements.length; e++) {
      fe = form.elements[e];
      if (fe.name && fe.value && !fe.disabled && fe.nodeName != "BUTTON") {
        val =
          fe.type == "checkbox" || fe.type == "radio"
            ? fe.checked
              ? fe.value || "on"
              : ""
            : fe.value;
        if (val) {
          if (forQS) arg.push(fe.name + "=" + encodeURIComponent(val));
          else arg[fe.name] = val;
        }
      }
    }
    return forQS ? arg.join("&") : arg;
  }

  // update form values
  function updateForm(form, obj) {
    var e, fe, nv;
    for (e = 0; e < form.elements.length; e++) {
      fe = form.elements[e];
      if (fe.name && fe.value && fe.nodeName != "BUTTON") {
        switch (fe.type) {
          case "checkbox":
            fe.checked = !!obj[fe.name];
            break;
          case "radio":
            fe.checked = !!(obj[fe.name] && obj[fe.name] == fe.value);
            break;
          default:
            fe.value = nv = decodeURIComponent(obj[fe.name] || "");
            if (
              fe.tagName == "SELECT" &&
              (fe.selectedIndex < 0 || fe.value != nv)
            ) {
              fe.selectedIndex = 0;
            }
        }
      }
    }
  }

  // event debouncing (delay passes without event reoccurring)
  function eventDebounce(element, event, callback, delay) {
    delay = delay || 300;
    var debounce;
    element.addEventListener(
      event,
      function (e) {
        if (debounce) clearInterval(debounce);
        debounce = setTimeout(function () {
          callback(e);
        }, delay);
      },
      false,
    );
  }

  // event throttling (will call every delay period regardless of event occurances)
  function eventThrottle(element, event, callback, delay) {
    delay = delay || 300;
    var throttle, latest;
    element.addEventListener(
      event,
      function (e) {
        if (throttle) {
          // latest event
          latest = e;
        } else {
          // prevent new events and callback
          throttle = setTimeout(function () {
            throttle = null;
            if (latest) callback(latest);
          }, delay);
          callback(e);
        }
      },
      false,
    );
  }

  // triggers callback when page is scrolled to or away from the end (once only)
  // callback passed true when at end, or false when not
  var stEnd,
    stCallback,
    stLast = false;
  function scrollToEnd(callback) {
    if (!stEnd) {
      // append end element
      var css = {
        clear: "both",
        display: "block",
        visibility: "hidden",
        pointerEvents: "none",
        height: "20vh",
        padding: 0,
        margin: 0,
      };

      stEnd = document.body.appendChild(document.createElement("div"));
      for (var p in css) stEnd.style[p] = css[p];

      // event triggers
      if ("IntersectionObserver" in window) {
        // use IntersectionObserver
        var observer = new IntersectionObserver(
          function (entry) {
            raiseEvent(entry.length && entry[0].isIntersecting);
          },
          { threshold: 0 },
        );

        observer.observe(stEnd);
      } else {
        // use interval fallback
        setInterval(function () {
          raiseEvent(endOfPage());
        }, 1000);
      }
    }

    // callback handler
    if (callback) {
      stCallback = stCallback || [];
      stCallback.push(callback);
      raiseEvent(endOfPage(), callback);
    }

    // at end of page
    function endOfPage() {
      return stEnd.offsetTop <= window.pageYOffset + window.innerHeight;
    }

    // run callback events
    function raiseEvent(atEnd, singleEvent) {
      if (atEnd === stLast) return;
      stLast = atEnd;

      // single startup callback
      if (singleEvent) singleEvent(atEnd);
      else if (stCallback && stCallback.length) {
        // run all callbacks
        stCallback.forEach(function (cb) {
          cb(atEnd);
        });
      }
    }
  }

  // Ajax handler:
  // obj				- form node or URL string (required)
  // callback		- return function passed err, url, data (optional)
  // appendData	- external function to append data (optional)
  // progress		- progress function (optional)
  // timeout		- timeout in ms (optional, 30 second default)
  function ajax(obj, callback, appendData, progress, timeout) {
    // settings
    var req,
      ptime = +new Date(),
      complete = false,
      timeoutCheck,
      url = obj,
      retUrl = url,
      method = "GET",
      data = null;

    callback = callback || function () {};
    timeout = timeout || 30000;

    if (typeof obj == "string") {
      // string passed
      url += (url.lastIndexOf("?") < 0 ? "?" : "&") + "ajax=1";
    } else {
      // form node passed
      url = retUrl = obj.action;
      method = (obj.method || "GET").toUpperCase();

      // get argument data
      if (method == "GET") {
        retUrl += "?" + getFormData(obj, true);
        url = retUrl + "&ajax=1";
      } else {
        if (obj.nodeType) data = new FormData(obj);
        else data = new FormData();
        if (appendData) data = appendData(data);
        data.append("ajax", 1);
      }
    }

    // initialise call
    req = new XMLHttpRequest();
    req.open(method, url);
    req.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    // progress handler
    req.upload.onprogress = function (p) {
      ptime = +new Date();
      if (progress) progress(p);
    };

    // state change
    req.onreadystatechange = function () {
      if (req.readyState != 4) return;
      complete = true;
      var error = req.status != 200,
        err = error ? req.response || error : null,
        data = null;
      if (!error) {
        try {
          data = JSON.parse(req.response);
        } catch (e) {
          data = req.response || null;
        }
      }
      callback(err, retUrl, data);
    };

    // start
    req.send(data);

    // timeout
    timeoutCheck = function () {
      // request already ended?
      if (complete) return;

      // recheck later
      if (+new Date() - ptime < timeout) setTimeout(timeoutCheck, 5000);
      else {
        // abort request
        complete = true;
        req.abort();
        callback("TIMEOUT", null);
      }
    };
    timeoutCheck();
  }

  // modal dialog (pass { message, header, buttons, callback })
  var mDialog, mDialogInner, mDialogCallback, mDialogNoCancel;
  function modal(opt) {
    opt.header = create(opt.header, "header");
    opt.message = create(opt.message, "p");
    mDialogNoCancel = !!opt.nocancel;
    mDialogCallback = opt.callback || function () {};

    if (!mDialog) {
      // create modal dialog element
      mDialog = document.body.appendChild(document.createElement("div"));
      mDialog.id = "modal";

      // dialog button or background clicked
      mDialog.addEventListener(
        "click",
        function (e) {
          e.preventDefault();
          var nn = e.target.nodeName;
          if (
            mDialogNoCancel ||
            (nn != "A" && lib.closest("div", e.target) == mDialogInner)
          )
            return;
          modalClose(nn == "A" ? e.target.dataset.value : null);
        },
        false,
      );
    }

    // class
    mDialog.className = opt.className || "";

    // create action buttons
    if (opt.buttons) {
      var b, li, link;
      opt.nav = document.createElement("ul");
      opt.nav.id = "nav";
      for (b in opt.buttons) {
        li = opt.nav.appendChild(document.createElement("li"));
        link = li.appendChild(document.createElement("a"));
        link.href = "#";
        link.className = "button";
        link.dataset.value = opt.buttons[b];
        link.textContent = b;
      }
    }

    // create dialog
    var inner = document.createElement("div");
    if (opt.header) inner.appendChild(opt.header);
    if (opt.message) inner.appendChild(opt.message);
    if (opt.nav) inner.appendChild(opt.nav);
    empty(mDialog);
    mDialogInner = mDialog.appendChild(inner);

    // show modal
    setTimeout(function () {
      mDialog.classList.add("active");
    }, 1);

    // create model content nodes
    function create(item, type) {
      if (item && typeof item === "string") {
        var n = document.createElement(type);
        n.textContent = item;
        item = n;
      }
      return item;
    }
  }

  // close modal dialog
  function modalClose(retValue) {
    if (mDialog && mDialog.classList.contains("active")) {
      mDialog.classList.remove("active");
      mDialogCallback(retValue);
    }
  }

  // public methods
  return {
    each: each,
    similar: similar,
    normalise: normalise,
    pad: pad,
    int: int,
    id: id,
    tag: tag,
    className: className,
    query: query,
    queryAll: queryAll,
    closest: closest,
    empty: empty,
    setSelect: setSelect,
    appliedStyle: appliedStyle,
    posTop: posTop,
    posLeft: posLeft,
    getFormData: getFormData,
    updateForm: updateForm,
    queryStringParse: queryStringParse,
    eventDebounce: eventDebounce,
    eventThrottle: eventThrottle,
    scrollToEnd: scrollToEnd,
    ajax: ajax,
    modal: modal,
    modalClose: modalClose,
  };
})();
