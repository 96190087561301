/*
  Test process updates
  Changes the process type and appends comments for Guardian + manual tests

  // requires: lib.js file-drop.js
*/
/* global lib lang */
(function () {
  "use strict";

  var cfg = {
    id: lib.query("input[name=_id]"),
    process: lib.id("process"),
    class: "autofill",
  };

  // no process field or support?
  if (
    !cfg.id ||
    !cfg.id.value ||
    !cfg.process ||
    !window.addEventListener ||
    !document.body.classList
  )
    return;

  // new asset event
  document.addEventListener("file", function (e) {
    if (!e.detail || e.detail.type !== "add") return;

    var pValue = process.value;

    // add timestamp and user name to file comment
    var comment = lib.query(
      'textarea[name="mediacomment"]',
      e.detail.element || null,
    );
    if (
      pValue !== "manual" &&
      pValue !== "client" &&
      comment &&
      comment.value === ""
    ) {
      comment.value = (
        (lang.todayTime || "") +
        " " +
        (lang.userName || "")
      ).trim();
    }

    // change process from Guardian to Guardian + manual
    if (
      pValue === "guardian" &&
      process.options[process.selectedIndex].defaultSelected
    ) {
      lib.setSelect(process, "guardman");
      process.classList.add(cfg.class);
    }
  });
})();
