/*
  Report table links
  Enables linking from report tables where the <table> has a data-linkurl attribute

  // requires: lib.js
*/
/* global lib */

(function () {
  "use strict";

  // dependencies
  var report = lib.queryAll("table.report[data-linkurl]");
  if (!window.addEventListener || !document.body.dataset || !report.length)
    return;

  // attach click event to table body
  lib.each(report, function (r) {
    var tbody = lib.tag("tbody", r);
    if (tbody.length) tbody[0].addEventListener("click", clickHandler, false);
  });

  // table cell click handler
  function clickHandler(e) {
    var t = e.target;
    if (t.nodeName != "TD" || t.textContent == "0") return;

    var table = lib.closest("table", t),
      colgroup = lib.tag("colgroup", table),
      row = lib.closest("tr", t),
      col = lib.tag("td", row),
      url = table.dataset.linkurl.replace(/&amp;/gi, "&"),
      qp = url.indexOf("?");

    colgroup = colgroup.length ? lib.tag("col", colgroup[0]) : null;
    if (url) {
      if (qp < 0) url += "?&";
      else {
        qp++;
        url = url.slice(0, qp) + "&" + url.slice(qp);
      }
    }

    // row data
    getField(col);
    getField(row);

    // column data
    if (colgroup) {
      getField(colgroup[t.cellIndex + colgroup.length - col.length]);
    }

    // redirect
    // if (url) { console.log(url.replace(/\?&/, '?')); return; }
    if (url) window.location = url.replace(/\?&/, "?");

    // add node field and value to URL
    function getField(nlist) {
      var n, tN, f, v;

      // make into array if necessary
      if (!nlist.length) nlist = [nlist];

      // find fields and values
      for (n = 0; n < nlist.length; n++) {
        tN = nlist[n];
        f = tN.dataset.field;
        v = tN.dataset.value;

        if (f && v) {
          // replace old value
          url = url.replace(new RegExp("&" + f + "=[^&]*", "gi"), "");
          url +=
            "&" + tN.dataset.field + "=" + encodeURIComponent(tN.dataset.value);
        }
      }
    }
  }
})();
