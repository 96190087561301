/*
  Test page auto-filling
  Adds further data when possible

  // requires: lib.js test-company.js
*/
/* global assure lib */

(function () {
  "use strict";

  var cfg = {
      delay: 200,
      ws: "/autofill/",
      sm: "/autofill-sim/",
      class: "autofill",
    },
    main = {
      country: lib.id("country"),
      landingurl: lib.id("landingurl"),
      urllist: lib.id("urllist"),
      network: lib.id("network"),
      serviceprice: lib.id("serviceprice"),
      servicefrequency: lib.id("servicefrequency"),
      servicetrialdays: lib.id("servicetrialdays"),
      landingpagetype: lib.id("landingpagetype"),
    },
    msisdn = lib.id("msisdn"),
    servicetrial = lib.id("servicetrial"),
    field = {},
    activeCountry,
    data,
    simsdata,
    loading;

  // no suppport
  if (
    !main.network ||
    !main.country ||
    !main.landingurl ||
    !main.urllist ||
    !main.serviceprice ||
    !main.landingpagetype ||
    !window.addEventListener ||
    !document.body.classList ||
    !msisdn
  )
    return;

  // initialise
  for (var m in main) {
    main[m].addEventListener("change", updateHandler, false);
  }
  main.country.addEventListener("change", updateMSISDNHandler, false);
  main.network.addEventListener("change", updateMSISDNHandler, false);

  // fetch sims data;
  document.addEventListener("DOMContentLoaded", fetchSimsData);

  // main watched field is updated
  function updateHandler(e) {
    var id = e.target.id;
    if (id && main.network.options[main.network.selectedIndex].value !== "xx") {
      fetchData(function (done) {
        if (done) updateFields(id);
      });
    }
  }

  // update fields when a value changes
  function updateFields(target) {
    if (!data) return;

    // lists loop
    var found, list, M, match, s, mgroup, v;

    for (list = 0; list < data.length; list++) {
      // matches
      M = data[list].M || {};
      v =
        M.fields && M.fields[0] && main[M.fields[0]]
          ? main[M.fields[0]].value.trim()
          : "";

      if (v && M.fields.indexOf(target) >= 0) {
        // parse list if value is set
        found = false;
        for (match in data[list]) {
          // matching landing
          if (match !== "M" && v.indexOf(match) >= 0) {
            // sub-matches
            for (s = 0; s < data[list][match].length; s++) {
              // sub-match check
              mgroup = data[list][match][s];
              found = !mgroup.M || checkSubGroup(mgroup.M);

              if (found) {
                autofill(mgroup);
                break;
              }
            }
          }

          // stop at found entry?
          if (found && M.matchstop) break;
        }
      }
    }
  }

  // check for matching subgroup
  function checkSubGroup(check) {
    var valid = true,
      value,
      f;

    for (f in check) {
      value = field[f].value;
      if (f === "urllist") value += "\n" + main.landingurl.value;
      valid = valid && value.indexOf(check[f]) >= 0;
    }

    return valid;
  }

  // autofill non-empty fields
  function autofill(group) {
    var value, elem, nName, nType, afill, f, c, comp, cNode;

    for (f in group) {
      // ignore validation entry
      if (f === "M") break;

      value = group[f];

      if (f === "company") {
        // company handler
        for (c = 0; c < value.length || 0; c++) {
          comp = value[c].trim();
          if (comp) {
            cNode = assure.addCompany(comp);
            if (cNode) cNode.classList.add(cfg.class);
          }
        }
      } else {
        // other fields handler
        elem = lib.id(f);
        value = String(value);

        if (elem && value) {
          nName = elem.nodeName.toLowerCase();
          nType = (elem.type || "").toLowerCase();
          afill = false;

          // select boxes
          if (nName === "select" && !elem.selectedIndex) {
            lib.setSelect(elem, group[f]);
            afill = true;
          }

          // input checkbox
          else if (nName === "input" && nType === "checkbox") {
            elem.checked = true;
            afill = true;
          }

          // radio buttons (not currently used)
          // else if (nName === 'input' && nType === 'radio') {}

          // text boxes
          else if (
            (nName === "input" || nName === "textarea") &&
            !elem.value.trim()
          ) {
            elem.value = value;
            afill = true;
          }

          if (f === "servicetrialdays") {
            elem.parentNode.style.visibility = "visible";
            servicetrial.checked = true;
          }

          // mark autofilled
          if (afill) elem.classList.add(cfg.class);
        }
      }
    }
  }

  // fetch country data
  function fetchData(callback) {
    if (loading || activeCountry == main.country.value) {
      if (!loading) callback(true);
      return;
    }

    loading = true;

    lib.ajax(cfg.ws + main.country.value, function (err, url, json) {
      loading = false;
      data = !err && json && json.length ? json : null;
      activeCountry = data ? main.country.value : null;

      parseData();
      callback(!!data);
    });
  }

  // parse dataset
  function parseData() {
    if (!data) return;

    // get fields in data list
    var match = [],
      list,
      M,
      f,
      mf,
      elem;

    for (list = 0; list < data.length; list++) {
      M = (data[list].M && data[list].M.fields) || [];

      // do fields
      for (f = 1; f < M.length; f++) {
        // field name
        mf = M[f];

        // add to checked fields
        if (!field[mf]) {
          elem = lib.id(mf);
          if (elem) {
            field[mf] = elem;
            if (!main[mf])
              field[mf].addEventListener("change", updateHandler, false);
          }
        }

        // add to match list
        if (!match[mf]) match.push(mf);
      }
    }

    // remove old field handlers
    for (f in field) {
      if (match.indexOf(f) < 0 && !main[f]) {
        field[f].removeEventListener("change", updateHandler, false);
        delete field[f];
      }
    }
  }

  function updateMSISDNHandler() {
    if (main.network.options[main.network.selectedIndex].value !== "xx") {
      filterSimsData((fdata) => {
        if (fdata) updateMSISDNField(fdata);
      });
    }
  }

  function fetchSimsData() {
    lib.ajax(cfg.sm, function (err, url, json) {
      simsdata = !err && json && json.length ? json : null;
    });
  }

  function filterSimsData(callback) {
    let fsimsdata = simsdata.filter((sim) => {
      return (
        sim.network == main.network.options[main.network.selectedIndex].value &&
        sim.country.toLowerCase() ==
          main.country.options[main.country.selectedIndex].value
      );
    });

    callback(fsimsdata);
  }

  function updateMSISDNField(array) {
    if (array.length < 1) {
      if (lib.id("msisdn-list")) lib.id("msisdn-list").remove();
      return;
    }

    if (lib.id("msisdn-list")) lib.id("msisdn-list").remove();

    var datalist = document.createElement("datalist");
    datalist.id = "msisdn-list";

    for (var i = 0; i < array.length; i++) {
      var option = document.createElement("option");
      option.value = array[i].msisdn;
      option.innerText = `device: ${array[i].device}`;
      datalist.appendChild(option);
    }

    msisdn.parentNode.insertBefore(datalist, msisdn.nextSibling);
  }
})();
